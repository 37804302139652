import useOutSideClick from "hooks/onOutSideClick";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface SideTabType {
  className?: string;
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

function SideTabContainer({ children }: { children: React.ReactNode }) {
  return createPortal(<>{children}</>, document.getElementById("sidetab")!);
}

const SideTab = ({ className, children, onClose, isOpen }: SideTabType) => {
  const sideTabRef = useRef(null);

  const handleClose = () => {
    onClose?.();
  };

  useOutSideClick(sideTabRef, handleClose);

  const sideTabClass = isOpen ? "right-0" : "-right-[400px]";

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  return (
    <SideTabContainer>
      <div
        className={`w-full h-full fixed left-0 top-0 z-[51] bg-Gray09/40 ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        } transition-opacity`}
      >
        <div
          ref={sideTabRef}
          className={`fixed top-0 bottom-0 w-[400px] h-full bg-white transition-all duration-300 ${sideTabClass} ${className}`}
        >
          {children}
        </div>
      </div>
    </SideTabContainer>
  );
};

export default SideTab;
