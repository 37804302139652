import React, { useState } from "react";

import MainWrap from "components/MainWrap";
import ArtistCard from "components/ArtistCard";
import YouTube from "react-youtube";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ArrowRight } from "assets/arrowRight.svg";

import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

import ad from "assets/ad.png";

import ContentWrap from "components/ContentWrap";
import CastingCard from "components/CastingCard";
import Chip from "components/Chip";
import { useMainCastingList, useMainHotList, useMainNewList } from "api/main";
import { MainCasting, MainCommon } from "type/common";

const Main = () => {
  const navigate = useNavigate();
  const [mainSwiper, setMainSwiper] = useState<SwiperClass>();
  const [newSwiper, setNewSwiper] = useState<SwiperClass>();
  const [hotSwiper, setHotSwiper] = useState<SwiperClass>();

  const [mainCastingType, setMainCastingType] = useState<MainCasting>({
    status: "",
    page: 0,
    size: 20,
  });
  const [mainHotType, setMainHotType] = useState<MainCommon>({
    page: 0,
    size: 20,
  });
  const [mainNewType, setMainNewType] = useState<MainCommon>({
    page: 0,
    size: 20,
  });

  const opt = {
    height: "159",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const typeList = [
    { id: 0, title: "전체", name: "" },
    { id: 1, title: "드라마", name: "TV_SERIES" },
    { id: 2, title: "영화", name: "1" },
    { id: 3, title: "예능", name: "2" },
    { id: 4, title: "광고", name: "3" },
    { id: 5, title: "뮤직비디오", name: "MUSIC_VIDEO" },
    { id: 6, title: "웹드라마", name: "WEB_SERIES" },
    { id: 7, title: "숏폼", name: "SHORT_FILM" },
    { id: 8, title: "독립영화", name: "4" },
  ];

  const hotPrev = () => {
    hotSwiper?.slidePrev();
  };

  const hotNext = () => {
    hotSwiper?.slideNext();
  };
  const newPrev = () => {
    newSwiper?.slidePrev();
  };

  const newNext = () => {
    newSwiper?.slideNext();
  };

  const { data: castingList } = useMainCastingList(mainCastingType);
  const { data: hotList } = useMainHotList(mainHotType);
  const { data: newList } = useMainNewList(mainNewType);

  return (
    <ContentWrap>
      <img className="mt-4" src={ad} alt=""></img>
      <MainWrap className="relative" more={true} title="진행중인 캐스팅">
        <div className="flex items-center gap-2 mb-4 max-w-full w-full overflow-x-scroll whitespace-nowrap">
          {typeList.map((item, i) => {
            return (
              <Chip
                selected={mainCastingType.status === item.name}
                onClick={() => {
                  setMainCastingType({
                    ...mainCastingType,
                    status: item.name,
                  });
                }}
                key={item.id}
                title={item.title}
              />
            );
          })}
        </div>
        <Swiper spaceBetween={8} centeredSlides={false} slidesPerView={3}>
          <SwiperSlide>
            <CastingCard />
          </SwiperSlide>
          <SwiperSlide>
            <CastingCard />
          </SwiperSlide>
          <SwiperSlide>
            <CastingCard />
          </SwiperSlide>
          <SwiperSlide>
            <CastingCard />
          </SwiperSlide>
        </Swiper>
      </MainWrap>
      <MainWrap className="relative" more={false} title="지금 뜨고있는 프로필">
        {hotList?.data.data.content.length! > 2 && (
          <div>
            <ArrowRight
              onClick={() => {
                hotPrev();
              }}
              className="absolute rotate-180 cursor-pointer right-[72px] top-0"
            />
            <ArrowRight
              onClick={() => {
                hotNext();
              }}
              className="absolute right-[14px] cursor-pointer top-0"
            />
          </div>
        )}
        <Swiper
        loop
          onSwiper={(e: SwiperClass) => {
            setHotSwiper(e);
          }}
          spaceBetween={8}
          slidesPerView={2}
        >
          {hotList?.data.data.content.map((item, i) => {
            return (
              <SwiperSlide key={item.profileId}>
                <ArtistCard
                  src={item.thumbnailUrl}
                  onClick={() => {
                    navigate(`/artist/${item.profileId}`);
                  }}
                  artistName={item.profileName}
                  artistJop={item.job.label}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </MainWrap>
      <MainWrap
        className="relative"
        title="새로 올라온 프로필"
        onClick={() => {
          navigate("/artist");
        }}
        more={true}
      >
        {newList?.data.data.content.length! > 2 && (
          <div>
            <ArrowRight
              onClick={() => {
                newPrev();
              }}
              className="absolute rotate-180 cursor-pointer right-[72px] top-0"
            />
            <ArrowRight
              onClick={() => {
                newNext();
              }}
              className="absolute right-[14px] cursor-pointer top-0"
            />
          </div>
        )}
        <Swiper
        loop
          spaceBetween={8}
          centeredSlides={false}
          slidesPerView={2}
          onSwiper={(e: SwiperClass) => {
            setNewSwiper(e);
          }}
        >
          {newList?.data.data.content.map((item, i) => {
            return (
              <SwiperSlide key={item.profileId}>
                <ArtistCard
                  src={item.thumbnailUrl}
                  onClick={() => {
                    navigate(`/artist/${item.profileId}`);
                  }}
                  artistName={item.profileName}
                  artistJop={item.job.label}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </MainWrap>
      <MainWrap more={false} title="캐스팅보트 핫클립">
        <YouTube
          className={"w-full"}
          opts={opt}
          iframeClassName="youtubeRounded"
          videoId="2g811Eo7K8U"
        />
      </MainWrap>
    </ContentWrap>
  );
};

export default Main;
