import React from "react";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import Chip from "./Chip";

interface StaffWorkCardType {
  onClick?: () => void;
}

const StaffWorkCard = ({ onClick }: StaffWorkCardType) => {
  return (
    <div>
      <div
        onClick={onClick}
        className="relative w-[172px] h-[211px] rounded-[10px] border cursor-pointer border-black"
      >
        <Chip selected className="bg-Blue01 border-none absolute left-4 top-4" title={"캐스팅중"}></Chip>
        <Bookmark className="absolute right-4 bottom-4 cursor-pointer" />
      </div>
      <div className="BBody16 mt-2 text-Gray09">킹덤 시즌2</div>
    </div>
  );
};

export default StaffWorkCard;
