import React from "react";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import test1 from "../assets/longTestImg.png";

interface NoticeCardType {
  image?: string;
  onClick?: () => void;
}

const NoticeCard = ({ image, onClick }: NoticeCardType) => {
  return (
    <div
      //  style={image ? {gridRow: "span 2"} : {}}
      onClick={onClick}
      className={`${
        image !== "" ? "h-full" : ""
      } cursor-pointer border border-[#D9D9D9] rounded-[10px] overflow-hidden`}
    >
      {image && <img src={test1} className="h-[149px]" alt="" />}
      <div className="w-full p-4">
        <div className="flex items-center justify-between">
          <div className="flex flex-wrap gap-2">
            <div className="MBody14 text-[#898A8D]">드라마</div>
            <div className="BBody14 text-[#898A8D]">종이의 집</div>
          </div>
          <Bookmark />
        </div>
        <div className="mt-2 mb-4 BBody18 overflow-hidden text-ellipsis text-nowrap w-[328px]">
          20~30대 여성 앵커 역할 출연자 모집합니다.
        </div>
        <div className="mb-1 MBody14 gap-2 flex items-center">
          <div>남녀무관</div>
          <div>|</div>
          <div>20대</div>
        </div>
        <div className="flex items-center justify-between">
          <div className="MBody14 text-[#898A8D]">2024.10.31까지</div>
          <div className="bg-[#FFEFEF] MCaption10 text-[#FF6060] px-2 py-1 rounded-[5px]">
            마감임박
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeCard;
