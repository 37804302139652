import React from "react";
import axios from "axios";
import { onLogOut } from "./onLogOut";

const requestToCastingVote = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  baseURL: "https://test.castingvote.tv/api/",
  withCredentials: true,
});

requestToCastingVote.interceptors.request.use(
  (response) => {
    return response;
  },
  function (error) {}
);

requestToCastingVote.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    async function Logout() {
      onLogOut();
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      alert("로그인이 필요합니다 로그인 해주세요.");
      await Logout();
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default requestToCastingVote;
