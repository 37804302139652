import React from "react";
import NoProfile from "../assets/noProfile.png";
import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";

const StaffMyCastingCall = () => {
  const navigate = useNavigate();
  return (
    <div>
      <HeaderWithBackButton title="내 캐스팅">
        <div onClick={() => {
            navigate("/staff/casting-call");
          }} className="cursor-pointer BBody16 text-Blue04">등록</div>
      </HeaderWithBackButton>
      <ContentWrap className="text-center mx-auto flex-col my-28">
        <img
          className="w-[60px] h-[60px] mx-auto"
          src={NoProfile}
          alt="NoProfile"
        />
        <div className="mt-4 mb-10 MBody16 text-Gray05 whitespace-normal">
          아직 공고가 없어요.
        </div>
        <Button
          className="px-4 py-3 text-Gray00 bg-Blue04 border-none"
          text={"공고 등록하기"}
          onClick={() => {
            navigate("/staff/casting-call");
          }}
        />
      </ContentWrap>
    </div>
  );
};

export default StaffMyCastingCall;
