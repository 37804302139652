import React from "react";

interface DateinputType {
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const DateInput = ({ className, onChange, value }: DateinputType) => {
  const today = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  return (
    <input
      type="date"
      min="1920-01-01"
      onChange={onChange}
      max={today()}
      required
      value={value}
      placeholder="생년월일을 선택해 주세요"
      className={` ${className}`}
    />
  );
};

export default DateInput;
