import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { ApiResponse, MainCasting, MainCommon } from "type/common";
import requestToCastingVote from "utils/requestToCastingVote";

const getMainCastingList = ({ status, page, size }: MainCasting) => {
  return requestToCastingVote({
    method: "GET",
    url: "home/castingcalls",
    params: {
      status,
      page,
      size,
    },
  });
};

export const useMainCastingList = (
  params: MainCasting
): UseQueryResult<AxiosResponse<ApiResponse>, Error> =>
  useQuery(["useMainCastingList", params], () => getMainCastingList(params), {
    retry: 3,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

const MainHot = ({ page, size }: MainCommon) => {
  return requestToCastingVote({
    method: "GET",
    url: "home/profiles/hot",
    params: {
      page,
      size,
    },
  });
};

export const useMainHotList = (
  params: MainCommon
): UseQueryResult<AxiosResponse<ApiResponse>, Error> =>
  useQuery(["useMainHotList", params], () => MainHot(params), {
    retry: 3,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

const getMainNewList = ({ page, size }: MainCommon) => {
  return requestToCastingVote({
    method: "GET",
    url: "home/profiles/new",
    params: {
      page,
      size,
    },
  });
};

export const useMainNewList = (
  params: MainCommon
): UseQueryResult<AxiosResponse<ApiResponse>, Error> =>
  useQuery(["useMainNewList", params], () => getMainNewList(params), {
    retry: 3,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
