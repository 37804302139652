import React from "react";

interface TextareaType {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea = ({
  className,
  value,
  placeholder,
  onChange,
}: TextareaType) => {
  return (
    <textarea
      onChange={onChange}
      placeholder={placeholder}
      defaultValue={value || ""}
      className={`border resize-none border-Gray03 RBody16 text-Gray05 p-[13px] rounded-[10px] ${className}`}
    ></textarea>
  );
};

export default Textarea;
