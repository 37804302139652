import React from "react";

interface MainWrapType {
  title: string;
  more: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

const MainWrap = ({ className, title, children, more, onClick }: MainWrapType) => {
  return (
    <div className={`mt-10 ${className}`}>
      <div className="
      BBody18
      ">{title}</div>
      <div className="pt-4 w-full">{children}</div>
      {more && (
        <div onClick={onClick} className="mt-4 text-right RBody14 cursor-pointer">
          더보기
        </div>
      )}
    </div>
  );
};

export default MainWrap;
