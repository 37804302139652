import React, { useEffect, useState } from "react";

import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import TitleWrap from "components/TitleWrap";
import { ReactComponent as IconPlus } from "assets/icon_plus.svg";
import Input from "components/Input";
import Textarea from "components/Textarea";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import { ProductionData, UpdateProductionRequest } from "type/staff";
import {
  useCreateProductionMutation,
  useProductionDetails,
  useUpdateProduction,
} from "api/staff";
import requestToCastingVote from "utils/requestToCastingVote";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import ImgUpload from "components/ImgUpload";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const CreateProduction = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const productionId = Number(searchParams.get("id"));

  const [postData, setPostData] = useState<ProductionData>({
    title: "",
    format: "",
    description: "",
    directorName: "",
    companyName: "",
    attachmentId: 0,
  });

  const { data, refetch } = useProductionDetails(productionId);

  const editData = data?.data.data;

  useEffect(() => {
    if (editData)
      setPostData({
        ...postData,
        title: editData?.title,
        format: editData?.format.code,
        description: editData?.description,
        directorName: editData?.directorName,
        companyName: editData?.companyName,
        attachmentUrl: editData?.thumbnailUrl,
      });
  }, [editData]);

  const { mutate: updateProduction } = useUpdateProduction();

  const { mutate } = useCreateProductionMutation(postData);

  const handleUpdate = () => {
    const productionData: UpdateProductionRequest = {
      productionId: productionId,
      ...postData,
    };

    updateProduction(productionData, {
      onSuccess: (response) => {
        navigate(-1);
      },
      onError: (error) => {
        alert("에러가 발생했습니다. 다시 시도해주세요.");
      },
    });
  };

  const type = [
    { id: 0, name: "드라마", title: "TV_SERIES" },
    { id: 1, name: "영화", title: "MOVIE" },
    { id: 2, name: "예능", title: "" },
    { id: 3, name: "광고", title: "" },
    { id: 4, name: "뮤직비디오", title: "" },
    { id: 5, name: "웹드라마", title: "WEB_SERIES" },
    { id: 6, name: "숏폼", title: "SHORT_FILM" },
    { id: 7, name: "독립영화", title: "" },
  ];

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    const formData = new FormData();
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        formData.append("files", fileList[i]);
      }
      requestToCastingVote({
        method: "POST",
        url: "upload/image",
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then((res) => {
          console.log(res.data.data);
          setPostData({
            ...postData,
            attachmentId: res?.data.data[0].attachmentId,
            attachmentUrl: res?.data.data[0].attachmentUrl,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  // staff/casting/my
  return (
    <ContentWrap className="pb-20">
      <HeaderWithBackButton title={`작품${productionId ? "수정" : "등록"}`} />
      <div className="pt-20 flex flex-col gap-10">
        <TitleWrap title={"대표사진 업로드"}>
          {postData.attachmentUrl ? (
            <div className="relative">
              <img
                className="w-full h-[216px] rounded-[10px] object-cover"
                src={`https://test.castingvote.tv/uploads${postData?.attachmentUrl}`}
                alt="ProfileImg"
              />
              <XButton
                onClick={() => {
                  setPostData({
                    ...postData,
                    attachmentId: 0,
                    attachmentUrl: "",
                  });
                }}
                className="absolute cursor-pointer top-4 right-4"
              />
            </div>
          ) : (
            <ImgUpload
              onChange={(e) => {
                imgUpload(e);
              }}
              id={"Prod_profile"}
            >
              <div className="w-full h-[216px] MBody16 flex flex-col justify-center text-center text-Blue04 border border-[#D9D9D9] border-dashed">
                <div>프로필 이미지 업로드</div>
                <IconPlus className="mx-auto mt-4" />
              </div>
            </ImgUpload>
          )}
        </TitleWrap>
        <TitleWrap require title={"작품제목"}>
          <Input
            value={postData?.title}
            onChange={(e) => {
              setPostData({ ...postData, title: e.target.value });
            }}
            type={"text"}
            placeholder="작품제목을 입력해 주세요"
          />
        </TitleWrap>
        <TitleWrap require title={"분야"}>
          <Dropdown
            innerText={
              postData.format === "MOVIE"
                ? "영화"
                : postData.format === "TV_SERIES"
                ? "드라마"
                : postData.format === "THEATER"
                ? "연극"
                : postData.format === "WEB_SERIES"
                ? "웹드라마"
                : postData.format === "SHORT_FILM"
                ? "숏폼"
                : "선택"
            }
            width="w-full"
          >
            <div>
              {type.map((item) => {
                return (
                  <li
                    onClick={() => {
                      setPostData({ ...postData, format: item.title });
                    }}
                    key={item.id}
                  >
                    {item.name}
                  </li>
                );
              })}
            </div>
          </Dropdown>
        </TitleWrap>
        <div className="flex items-center gap-4">
          <TitleWrap title={"감독"}>
            <Input
              value={postData?.directorName}
              onChange={(e) => {
                setPostData({ ...postData, directorName: e.target.value });
              }}
              type={"text"}
              placeholder="감독을 입력해 주세요"
            />
          </TitleWrap>
          <TitleWrap title={"제작사"}>
            <Input
              value={postData?.companyName}
              onChange={(e) => {
                setPostData({ ...postData, companyName: e.target.value });
              }}
              type={"text"}
              placeholder="제작사를 입력해 주세요"
            />
          </TitleWrap>
        </div>
        <TitleWrap require title={"작품설명"}>
          <Textarea
            value={postData?.description}
            onChange={(e) => {
              setPostData({ ...postData, description: e.target.value });
            }}
            className="w-full h-[210px]"
            placeholder="작품설명을 입력해 주세요"
          />
        </TitleWrap>
      </div>
      <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 border-t border-Gray03 mt-5 py-4 px-5">
        <Button
          onClick={() => {
            if (productionId) {
              handleUpdate();
            } else {
              mutate(postData, {
                onSuccess: (data) => {
                  if (data.data.code === 200) {
                    alert("공고가 성공적으로 등록되었습니다.");
                  }
                },
                onError: (error) => {
                  alert("에러가 발생했습니다");
                },
              });
            }
          }}
          className="w-full h-12 bg-Blue04 text-white border-none"
          text={`${productionId ? "수정" : "등록"}하기`}
        ></Button>
      </div>
    </ContentWrap>
  );
};

export default CreateProduction;
