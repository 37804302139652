import React from "react";

interface ProfileInfoCardType {
  name: string;
  info: string;
  size?: string;
  onClick?: () => void;
  src?: string;
}

const ProfileInfoCard = ({
  name,
  info,
  size,
  src,
  onClick,
}: ProfileInfoCardType) => {
  return (
    <div className="">
      <img
        src={`https://test.castingvote.tv/uploads${src}`}
        alt=""
        onClick={onClick}
        className="w-[172px] h-[216px] object-cover cursor-pointer rounded-[10px]"
      />
      <div className="flex gap-2 mt-2 items-center">
        <div className="BBody16">{name}</div>
        <div className="MBody14 text-[#898A8D]">{`${
          info?.split("-")[0]
        }년생`}</div>
      </div>
      {size && <div className="MBody14 text-[#898A8D]">{size}</div>}
    </div>
  );
};

export default ProfileInfoCard;
