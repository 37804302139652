import React from "react";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";

interface CastingRecruitCardType {
  onClick?: () => void;
}

const CastingRecruitCard = ({ onClick }: CastingRecruitCardType) => {
  return (
    <div
      onClick={onClick}
      className="w-full cursor-pointer p-4 border border-[#D9D9D9] rounded-[10px]"
    >
      <div className="flex items-center text-Gray05 MBody14 justify-between">
        <div>주연</div>
        <Bookmark />
      </div>
      <div className="max-w-[326px] my-2 BBody18 w-full">
        옴니버스 장편영화중 공포 스릴러 1편 여성 단독주연 하실 여성분 1분
        구합니다. [ 9월말 10월초 1~2회차 촬영]
      </div>
      <div className="flex items-center MBody14 text-Gray09 gap-2">
        <div>여성</div>
        <div>|</div>
        <div>나이무관</div>
      </div>
    </div>
  );
};

export default CastingRecruitCard;
