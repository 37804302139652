import React, { useEffect, useState } from "react";
import { Route, Router, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Layout from "./components/Layout";
import Login from "pages/Login";
import Signup from "pages/Signup";

import ArtistList from "pages/ArtistList";
import ArtistDetail from "pages/ArtistDetail";
import StaffList from "pages/StaffList";
import StaffDetail from "pages/StaffDetail";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "react-datepicker/dist/react-datepicker.css";

import CastingList from "pages/CastingList";
import CastingDetail from "pages/CastingDetail";
import StaffFilmoDetail from "pages/StaffFilmoDetail";
import CreateArtistProfile from "pages/CreateArtistProfile";
import CreateStaffProfile from "pages/CreateStaffProfile";

// import { useLoginCheck } from "api/auth";
import { info, loginCheck } from "store/auth/atom";
import { useRecoilState } from "recoil";
import StaffMy from "pages/StaffMy";
import ArtistMy from "pages/ArtistMy";
import Mypage from "pages/Mypage";
import CreateCastingCall from "pages/CreateCastingCall";
import CreateProduction from "pages/CreateProduction";
import { useLoginCheck } from "api/auth";
import StaffMyCasting from "pages/StaffMyCasting";
import { onLogOut } from "utils/onLogOut";
import StaffMyCastingCall from "pages/StaffMyCastingCall";

function App() {
  const [login, setLogin] = useRecoilState(loginCheck);
  const updatedUserInfo = localStorage.getItem("user");
  let userInfo = updatedUserInfo ? JSON.parse(updatedUserInfo) : null;
  const [userData, setUserData] = useRecoilState<{
    id: string;
    username: string;
    role: string;
  }>(info);

  const { data } = useLoginCheck(!!userInfo?.id);
  
  useEffect(() => {
    if (data) {
      if (data.data.message === "OK") {
        const info = data.data.data;
        const id = String(info?.memberId);
        const userInfo = {
          id: id,
          username: info.username,
          role: info.role,
        };
        const userJson = JSON.stringify(userInfo);
        setUserData(userInfo);
        localStorage.clear();
        localStorage.setItem("user", userJson);
        setLogin(true)
      } else {
        onLogOut()
      }
    }
  }, [data]);

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
  useEffect(() => {
    setScreenSize();
  });

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        <Route path="/my" element={<Mypage />} />

        <Route path="/artist" element={<ArtistList />} />
        <Route path="/artist/:artistId" element={<ArtistDetail />} />
        <Route path="/artist/profile" element={<CreateArtistProfile />} />
        <Route path="/artist/my" element={<ArtistMy />} />

        <Route path="/staff" element={<StaffList />} />
        <Route path="/staff/:staffId" element={<StaffDetail />} />
        <Route path="/staff/profile" element={<CreateStaffProfile />} />
        <Route path="/staff/my" element={<StaffMy />} />
        <Route path="/staff/casting/my" element={<StaffMyCasting />} />
        <Route path="/staff/post" element={<CreateProduction />} />
        <Route path="/staff/casting-call" element={<CreateCastingCall />} />
        <Route path="/staff/casting-call/my" element={<StaffMyCastingCall />} />
        <Route path="/staff/filmo/:staffId" element={<StaffFilmoDetail />} />

        <Route path="/casting" element={<CastingList />} />
        <Route path="/casting/:itemId" element={<CastingDetail />} />
      </Route>
    </Routes>
  );
}

export default App;
