import { computeHeadingLevel } from "@testing-library/react";
import React from "react";

interface TitleWrapType {
  title: string;
  subTitle?: string;
  children: React.ReactNode;
  className?: string;
  require?: boolean;
  T20?: boolean;
}

const TitleWrap = ({
  title,
  subTitle,
  className,
  children,
  require,
  T20
}: TitleWrapType) => {
  return (
    <div className={`flex flex-col gap-4 ${className}`}>
      <div className={`flex items-center`}>
        <div className={`${T20 ? "BBody20" : "BBody18"}`}>{title}</div>
        {require && <div className="text-Blue04">*</div>}
        {subTitle && (
          <div className="RCaption10 ml-2 text-[#898A8D]">{subTitle}</div>
        )}
      </div>
      <div className="relative">{children}</div>
    </div>
  );
};

export default TitleWrap;
