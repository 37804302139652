import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import ContentWrap from "components/ContentWrap";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import SearchBox from "components/SearchBox";
import SideTab from "components/SideTab";
import StaffCard from "components/StaffCard";
import useDebounce from "hooks/useDebounce";
import { useStaffList } from "api/staff";
import Chip from "components/Chip";
import TitleWrap from "components/TitleWrap";
import Button from "components/Button";

const StaffList = () => {
  const navigate = useNavigate();
  const [isSideTabOpen, setIsSideTabOpen] = useState(false);

  const handleOpenTab = () => setIsSideTabOpen(true);
  const handleCloseTab = () => setIsSideTabOpen(false);
  const [staffData, setStaffData] = useState({
    name: "",
    role: "",
    page: 0,
    size: 20,
  });

  const [sideFilter, setSideFilter] = useState(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearchText = useDebounce(search, 500);

  const { data } = useStaffList({
    name: debouncedSearchText,
    role: "",
    page: 0,
    size: 20,
  });

  const gender = [
    {
      id: 0,
      title: "여성",
      name: "FEMALE",
    },
    { id: 1, title: "남성", name: "MALE" },
  ];
  const job = [
    { id: 0, title: "캐스팅디렉터", name: "" },
    { id: 1, title: "감독", name: "" },
    { id: 2, title: "프로듀서", name: "" },
    { id: 3, title: "매니저", name: "" },
  ];
  const keyword = [
    { id: 1, title: "드라마", name: "" },
    { id: 2, title: "영화", name: "" },
    { id: 3, title: "예능", name: "" },
    { id: 4, title: "광고", name: "" },
    { id: 5, title: "숏폼", name: "" },
    { id: 6, title: "독립영화", name: "" },
  ];

  return (
    <ContentWrap className="pb-8">
      <SideTab isOpen={isSideTabOpen} onClose={handleCloseTab}>
        <div className="pl-2 p-4 flex items-center justify-between">
          <div
            onClick={handleCloseTab}
            className="MBody18 cursor-pointer flex items-center"
          >
            <ArrowLeft />
            <div>필터</div>
          </div>
          <div className="BBody18 cursor-pointer text-Blue04">초기화</div>
        </div>
        <div className="pt-6 pl-4 pb-[100px] flex flex-col items-start gap-10">
          <TitleWrap title="성별" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {gender.map((item, i) => {
                return <Chip key={item.id} title={item.title}></Chip>;
              })}
            </div>
          </TitleWrap>
          <TitleWrap title="직업" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {job.map((item, i) => {
                return <Chip key={item.id} title={item.title}></Chip>;
              })}
            </div>
          </TitleWrap>
          <TitleWrap title="키워드" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {keyword.map((item, i) => {
                return <Chip key={item.id} title={item.title}></Chip>;
              })}
            </div>
          </TitleWrap>
        </div>
        <div className="fixed pb-[19px] bottom-0 px-4">
          <Button
            className="w-[320px] border-none bg-Blue04 text-white"
            text={"필터 적용하기"}
          ></Button>
        </div>
      </SideTab>
      <div className="pt-4">
        <SearchBox
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          className="w-full"
          placeholder="스탭 이름을 입력해 주세요"
        />
      </div>
      <div className="text-right my-4">
        <Chip onClick={handleOpenTab} title={"필터"}></Chip>
      </div>
      <div className="pb-20 flex flex-col gap-4">
        {data?.data.data.content.map((item) => {
          return (
            <StaffCard
              key={item.profileId}
              image={item?.thumbnailUrl}
              role={item?.role.label}
              agency={item?.agency}
              name={item?.profileName}
              onClick={() => navigate(`${item.profileId}`)}
            />
          );
        })}
      </div>
    </ContentWrap>
  );
};

export default StaffList;
