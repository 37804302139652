import { AxiosError, AxiosResponse } from "axios";
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from "react-query";
import { useNavigate } from "react-router-dom";

import {
  Login,
  LoginCheckData,
  LoginData,
  Signup,
  SignupData,
} from "type/auth";
import requestToCastingVote from "utils/requestToCastingVote";

const signup = ({
  userId,
  password,
  name,
  phoneNumber,
  email,
  gender,
  birthDate,
  zonecode,
  roadAddress,
  sido,
  jibunAddress,
  sigungu,
  termsOfService,
  termsOfServiceAt,
  privacyPolicy,
  privacyPolicyAt,
  thirdPartySharing,
  thirdPartySharingAt,
  marketingOptIn,
  marketingOptInAt,
  recommendId,
  role,
  businessLicenseId,
  businessName,
  businessNumber,
}: Signup) => {
  return requestToCastingVote({
    method: "POST",
    url: "auth/signup",
    data: {
      userId,
      password,
      name,
      phoneNumber,
      email,
      gender,
      birthDate,
      zonecode,
      roadAddress,
      jibunAddress,
      sido,
      sigungu: sigungu,
      termsOfService: termsOfService,
      termsOfServiceAt: termsOfServiceAt,
      privacyPolicy: privacyPolicy,
      privacyPolicyAt: privacyPolicyAt,
      thirdPartySharing: thirdPartySharing,
      thirdPartySharingAt: thirdPartySharingAt,
      marketingOptIn: marketingOptIn,
      marketingOptInAt: marketingOptInAt,
      recommendId: recommendId,
      role: role,
      businessName: businessName,
      businessNumber: businessNumber,
      businessLicenseId: businessLicenseId,
    },
  });
};

export const useSignupMutation = (
  params: Signup
): UseMutationResult<
  AxiosResponse<SignupData>, // 성공 시 반환 타입
  AxiosError<{
    code: number;
    status: string;
    message: string;
    data: string[] | string;
  }>, // 에러 시 반환 타입
  Signup // 뮤테이션에 전달되는 변수 타입
> => {

  const signupMutation = useMutation<
    AxiosResponse<SignupData>, // 성공 시 반환 타입
    AxiosError<{
      code: number;
      status: string;
      message: string;
      data: string[] | string;
    }>, // 에러 타입
    Signup // 전달되는 파라미터 타입
  >({
    mutationFn: signup,
  });

  return signupMutation;
};

const login = ({ userId, password, remember }: Login) => {
  return requestToCastingVote({
    method: "POST",
    url: "auth/login",
    data: {
      userId: userId,
      password: password,
      remember: remember,
    },
  });
};

export const useLoginMutation = (
  params: Login
): UseMutationResult<AxiosResponse<LoginCheckData>, Error, Login> => {

  const loginMutation = useMutation<
    AxiosResponse<LoginCheckData>,
    AxiosError,
    Login
  >({
    mutationFn: login,
    onError: (error: AxiosError) => {
      const errData = error.response?.data;
      alert("아이디 또는 비밀번호가 잘못되었습니다.");
    },
  });
  return loginMutation;
};

const getLoginCheck = (islogin:boolean) => {
  return requestToCastingVote({
    method: "GET",
    url: "auth",
  });
};

export const useLoginCheck = (isLogin:boolean): UseQueryResult<
  AxiosResponse<LoginCheckData>,
  Error
> =>
  useQuery(["useLoginCheck", isLogin], () => getLoginCheck(isLogin), {
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: isLogin
  });
