import React from "react";

interface CheckBoxType {
  title: string;
  className?: string;
  onClick?:() => void;
  onChange?:(e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?:boolean
  id?: string;
}

const Checkbox = ({ title, id, className, onChange, onClick, checked }: CheckBoxType) => {
  return (
    <div className="flex items-center">
      <input
        id={id}
        type="checkbox"
        value=""
        checked={checked}
        onChange={onChange}
        className="appearance-none w-[18px] h-[18px] bg-check checked:bg-checked bg-no-repeat bg-center"
      />
      <label
      onClick={onClick}
        htmlFor={id}
        className={`nonDragable ext-sm font-medium text-gray-900 dark:text-gray-300 ms-2 ${className}`}
      >
        {title}
      </label>
    </div>
  );
};

export default Checkbox;
