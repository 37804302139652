import React from "react";
import ContentWrap from "./ContentWrap";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Back } from "assets/headerBack.svg";

interface HeaderWithBackButtonType {
  title: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

const HeaderWithBackButton = ({
  title,
  onClick,
  children,
}: HeaderWithBackButtonType) => {
  const navigate = useNavigate();
  return (
    <div className="flex fixed bg-Gray00 left-2/4 top-0 z-50 -translate-x-2/4 max-w-[400px] w-full BBody20 text-Gray09 justify-between items-center mx-auto px-2 py-2">
      <Back
        className="cursor-pointer"
        onClick={() => {
          navigate(-1);
        }}
      />
      <div>{title}</div>
      <div onClick={onClick}>{children}</div>
    </div>
  );
};

export default HeaderWithBackButton;
