import React, { useState } from "react";

import KakaoLogin from "../assets/kakaoLogin.png";

import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import { useNavigate } from "react-router-dom";
import Checkbox from "components/Checkbox";
import ContentWrap from "components/ContentWrap";
import { useLoginMutation } from "api/auth";
import BottomPopup from "components/BottomPopup";
import { useRecoilState } from "recoil";
import { loginCheck } from "store/auth/atom";
import { LoginCheckData } from "type/auth";
import { AxiosResponse } from "axios";

const Login = () => {
  const navigate = useNavigate();
  const [agreeTermModal, setAgreeTermModal] = useState(false);
  const [checkItems, setCheckItems] = useState<number[]>([]);
  const [login, setLogin] = useRecoilState(loginCheck);

  const [loginData, setLoginData] = useState({
    userId: "",
    password: "",
    remember: "false",
  });
  const [selectAgree, setSelectAgree] = useState({
    termsOfService: false,
    termsOfServiceAt: "",
    privacyPolicy: false,
    privacyPolicyAt: "",
    thirdPartySharing: false,
    thirdPartySharingAt: "",
    marketingOptIn: false,
    marketingOptInAt: "",
  });

  const checkList = [
    { id: 0, title: "서비스 이용약관 동의 (필수)", type: "termsOfService" },
    {
      id: 1,
      title: "개인정보 수집 및 이용 동의 (필수)",
      type: "privacyPolicy",
    },
    { id: 2, title: "제 3자 정보 제공 동의 (필수)", type: "thirdPartySharing" },
    { id: 3, title: "마케팅 수신 동의 (선택)", type: "marketingOptIn" },
  ];

  const requiredValues = [0, 1, 2];

  const isFormValid = requiredValues.every((val) => checkItems.includes(val));

  const checkItemHandler = (id: number, isChecked: boolean, type: string) => {
    const formattedDate = new Date().toISOString().slice(0, 19);
    if (isChecked) {
      setCheckItems((prev) => [...prev, id]);
      setSelectAgree((pre) => ({
        ...pre,
        [type]: true,
        [`${type}At`]: formattedDate,
      }));
    } else {
      setCheckItems(checkItems.filter((item) => item !== id));
      setSelectAgree((pre) => ({ ...pre, [type]: false, [`${type}At`]: "" }));
    }
  };

  const allCheckedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDate = new Date().toISOString().slice(0, 19);
    if (e.target.checked) {
      setCheckItems(checkList.map((item) => item.id));
      setSelectAgree((pre) => ({
        ...pre,
        termsOfService: true,
        termsOfServiceAt: formattedDate,
        privacyPolicy: true,
        privacyPolicyAt: formattedDate,
        thirdPartySharing: true,
        thirdPartySharingAt: formattedDate,
        marketingOptIn: true,
        marketingOptInAt: formattedDate,
      }));
    } else {
      setCheckItems([]);
      setSelectAgree({
        termsOfService: false,
        termsOfServiceAt: "",
        privacyPolicy: false,
        privacyPolicyAt: "",
        thirdPartySharing: false,
        thirdPartySharingAt: "",
        marketingOptIn: false,
        marketingOptInAt: "",
      });
    }
  };

  const { mutate } = useLoginMutation(loginData);

  const successLogin = (data: AxiosResponse<LoginCheckData>) => {
    if (data.data.code === 200) {
      alert("성공");
      setLogin(true);
      const info = data.data.data;
      const id = String(info?.memberId);
      const userInfo = {
        id: id,
        username: info.username,
        role: info.role,
      };
      const userJson = JSON.stringify(userInfo);
      localStorage.clear();
      localStorage.setItem("user", userJson);
      navigate("/");
    } else {
      alert(`${data.data.message}"`);
    }
  };

  return (
    <ContentWrap className="flex flex-col h-screen gap-10">
      {agreeTermModal && (
        <BottomPopup onClose={() => setAgreeTermModal(false)}>
          <div>
            <div className="BHead24 mb-4">
              회원가입 전에
              <br />
              약관 동의가 필요해요.
            </div>
            <div className="flex flex-col gap-4">
              {checkList.map((item) => {
                return (
                  <Checkbox
                    id={item.title}
                    checked={checkItems.includes(item.id) ? true : false}
                    onChange={(e) => {
                      checkItemHandler(item.id, e.target.checked, item.type);
                    }}
                    key={item.id}
                    title={item.title}
                  />
                );
              })}
            </div>
            <hr className="w-full h-px my-4 bg-[#D9D9D9]" />
            <Checkbox
              id="전체선택"
              checked={checkItems.length === checkList.length ? true : false}
              onChange={allCheckedHandler}
              className=""
              title="전체 동의하기"
            />
            <Button
              disabled={!isFormValid}
              onClick={() => navigate("/signup", { state: selectAgree })}
              className="w-[348px] mt-4 BBody16 text-white bg-Blue04"
              text="동의하고 계속하기"
            />
          </div>
        </BottomPopup>
      )}
      <div className="text-center BBody20 mt-10 mx-auto">
        캐스팅을 향한 빠른 길,
        <br />
        캐스팅보트를 시작하세요 🙌
      </div>
      <div className="flex flex-col gap-4">
        <Input
          onChange={(e) => {
            setLoginData({ ...loginData, userId: e.target.value });
          }}
          value={loginData.userId}
          placeholder="아이디를 입력해 주세요"
          className=" w-full"
          type="text"
        />
        <Input
          onChange={(e) => {
            setLoginData({ ...loginData, password: e.target.value });
          }}
          value={loginData.password}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              mutate(loginData, {
                onSuccess: (data) => {
                  successLogin(data);
                },
              });
            }
          }}
          placeholder="비밀번호를 입력해 주세요"
          className=" w-full"
          type="password"
        />
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              setLoginData({ ...loginData, remember: "true" });
            } else {
              setLoginData({ ...loginData, remember: "false" });
            }
          }}
          id="login"
          className="RBody14 text-[#898A8D]"
          title="로그인 유지"
        />
      </div>
      <div className="flex text-center flex-col gap-4 max-w-[520px]">
        <Button
          onClick={() => {
            mutate(loginData, {
              onSuccess: (data) => {
                successLogin(data);
              },
            });
          }}
          className="w-full mx-auto text-Gray00 text-center bg-Blue04"
          text="로그인"
        />
        <Button
          onClick={() => {
            setCheckItems([]);
            setAgreeTermModal(true);
          }}
          className="w-full text-Blue04"
          text="이메일로 가입"
        />
        <div className="flex items-center mx-auto gap-4 RBody14 text-[#898A8D]">
          <div>아이디 찾기</div>
          <div>|</div>
          <div>비밀번호 찾기</div>
        </div>
      </div>

      <div className="flex flex-col gap-4 mx-auto w-full">
        <div className="flex items-center justify-between mx-auto gap-[41px]">
          <hr className="flex-1 h-px bg-[#D9D9D9]" />
          <div className="text-sm MBody14 text-[#898A8D]">SNS 간편 로그인</div>
          <hr className="flex-1 h-px bg-[#D9D9D9]" />
        </div>
        <div className="text-sm RBody14 cursor-pointer grid gap-2 text-Gray09 items-center mx-auto">
          <img src={KakaoLogin} alt="snsLogin" />
          <div>카카오톡</div>
        </div>
      </div>
    </ContentWrap>
  );
};

export default Login;
