import React from "react";
import FooterLogo from "../assets/footerLogo.png";
import { ReactComponent as Insta } from "../assets/instagram.svg";
import { ReactComponent as Kakao } from "../assets/kakao.svg";
import { ReactComponent as Blog } from "../assets/blog.svg";
import { ReactComponent as Youtube } from "../assets/youtube.svg";
import ContentWrap from "./ContentWrap";
import { NavLink, useLocation, useParams } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const params = useParams();

  const Sns = [
    { title: "youtube", src: Youtube },
    { title: "blog", src: Blog },
    { title: "insta", src: Insta },
    { title: "kakao", src: Kakao },
  ];
  //   인스타그램 https://www.instagram.com/castingvote.io/
  // 페이스북 https://www.facebook.com/people/%EC%BA%90%EC%8A%A4%ED%8C%85%EB%B3%B4%ED%8A%B8/61561061871831/
  // 틱톡 https://www.tiktok.com/@castingvote.official

  const Info = [
    {
      title: "대표",
      content: "유성봉",
      needGap: true,
    },
    {
      title: "사업자등록번호",
      content: "772-87-03229",
      needGap: true,
    },
    {
      title: "통신판매업 신고번호",
      content: "2024-서울강남-02551호",
      needGap: true,
    },
    {
      title: "고객센터",
      content: "02-517-2468",
      needGap: true,
    },
    {
      title: "개인정보정책 및 담당",
      content: "유성봉",
      needGap: true,
    },
    {
      title: "광고 및 제휴문의",
      content: "support@castingvote.com",
      needGap: true,
    },
  ];

  if (location.pathname !== "/") return null;

  return (
    <ContentWrap className="w-full mx-auto pt-[30px] pb-24 bg-Gray08 px-5">
      <div className="flex justify-between mb-[30px]">
          <img className="w-20 h-[30px]" src={FooterLogo} alt="로고" />
        <div className="flex gap-6 min-w-[184px]">
          {Sns.map((item, i) => {
            return <item.src key={i} className="w-7 h-7" />;
          })}
        </div>
      </div>
      <div className="text-white flex flex-col RBody14 gap-1 ">
        <div className="flex gap-2">
          <div className="font-bold">대표</div>
          <div>유성봉</div>
        </div>
        <div>
          <div className="flex gap-x-4 flex-wrap">
            <div className="flex gap-2">
              <div className="font-bold">사업자등록번호</div>
              <div>772-87-03229</div>
            </div>
            <div className="flex gap-2">
              <div className="font-bold">통신판매업 신고번호</div>
              <div>2024-서울강남-02551호</div>
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="font-bold">주소</div>
          <div>서울 강남구 영동대로 701 W타워 8층 (청담동)</div>
        </div>
        <div className="flex gap-x-4 flex-wrap">
          <div className="flex gap-2">
            <div className="font-bold">고객센터</div>
            <div>02-512-2468</div>
          </div>
          <div className="flex gap-2">
            <div className="font-bold">개인정보정책 및 담당</div>
            <div>유성봉</div>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="font-bold">광고 및 제휴문의</div>
          <div>support@castingvote.com</div>
        </div>
      </div>
      <div className="flex gap-2 text-white BBody14 mt-10">
          <div>회사소개</div>
          <div>공지사항</div>
          <div>개인정보처리방침</div>
          <div>이용약관</div>
          <div>광고문의</div>
        </div>
      <hr className="text-white opacity-10 my-4" />
        <div className="text-white RBody14 opacity-30">
          Copyright ⓒ CASTING VOTE ALL RIGHTS RESERVED.
      </div>
    </ContentWrap>
  );
};

export default Footer;
