import { AxiosError, AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import {
  CreateCastingCallData,
  CreateCastingCallResponse,
  CreateStaffProfile,
  CreateStaffProfileData,
  EditProductionResponseData,
  getProduction,
  MyPageData,
  ProductionData,
  ProductionDetailResponse,
  ProductionQueryParams,
  ProductionResponse,
  ProductionsResponse,
  StaffDetailData,
  StaffList,
  StaffListData,
  UpdateProductionRequest,
  UpdateProductionResponse,
} from "type/staff";
import requestToCastingVote from "utils/requestToCastingVote";

const createStaff = ({ filmographies, attachments }: CreateStaffProfile) => {
  return requestToCastingVote({
    method: "POST",
    url: "profiles/staff",
    data: {
      filmographies,
      attachments,
    },
  });
};

export const useCreateStaffProfileMutation = (
  data: CreateStaffProfile
): UseMutationResult<
  AxiosResponse<CreateStaffProfileData>,
  Error,
  CreateStaffProfile
> => {
  const createStaffMutation = useMutation<
    AxiosResponse<CreateStaffProfileData>,
    AxiosError,
    CreateStaffProfile
  >({
    mutationFn: createStaff,
    onSuccess: (data, variables, context) => {
      if (data.data.code === 200) {
        alert("성공");
      } else {
        alert("실패");
      }
    },
    // onSettled: () => {
    //   console.log("Mutation settled");
    // },
    onError: (error) => {
      console.error(error.response?.data);
    },
  });

  return createStaffMutation;
};

const getStaffList = ({ name, role, page, size }: StaffList) => {
  return requestToCastingVote({
    method: "GET",
    url: "profiles/staff",
    params: {
      name,
      role,
      page,
      size,
    },
  });
};

export const useStaffList = (
  params: StaffList
): UseQueryResult<AxiosResponse<StaffListData>, Error> =>
  useQuery(["useStaffList", params], () => getStaffList(params), {
    retry: 2,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

const getStaffDetail = (id: number) => {
  return requestToCastingVote({
    method: "GET",
    url: `profiles/${id}/staff`,
  });
};

export const useStaffDetail = (
  id: number
): UseQueryResult<AxiosResponse<StaffDetailData>, Error> =>
  useQuery(["useStaffDetail"], () => getStaffDetail(id), {
    retry: 1,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

const getMyPage = () => {
  return requestToCastingVote({
    method: "GET",
    url: "/mypage/profile",
  });
};

export const useMyPage = (): UseQueryResult<AxiosResponse<MyPageData>, Error> =>
  useQuery(["useMyPage"], () => getMyPage(), {
    retry: 1,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

const fetchProductions = (): Promise<AxiosResponse<ProductionsResponse>> => {
  return requestToCastingVote.get("mypage/productions");
};

export const useProductionsQuery = (): UseQueryResult<
  AxiosResponse<ProductionsResponse>,
  AxiosError
> => {
  return useQuery("productions", fetchProductions, {
    retry: 1,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

const createProduction = ({
  title,
  format,
  description,
  directorName,
  companyName,
  attachmentId,
}: ProductionData) => {
  return requestToCastingVote({
    method: "POST",
    url: "productions",
    data: {
      title,
      format,
      description,
      directorName,
      companyName,
      attachmentId,
    },
  });
};

export const useCreateProductionMutation = (
  data: ProductionData
): UseMutationResult<
  AxiosResponse<ProductionResponse>,
  AxiosError,
  ProductionData
> => {
  const createProductionMutation = useMutation<
    AxiosResponse<ProductionResponse>,
    AxiosError,
    ProductionData
  >({
    mutationFn: createProduction,
  });

  return createProductionMutation;
};

const getProductions = (
  params: ProductionQueryParams
): Promise<AxiosResponse<getProduction>> => {
  return requestToCastingVote("productions", {
    params: {
      title: params.title,
      format: params.format,
      directorName: params.directorName,
      companyName: params.companyName,
      page: params.page ?? 0, // 기본값 0
      size: params.size ?? 20, // 기본값 20
    },
  });
};

export const useProductions = (
  params: ProductionQueryParams
): UseQueryResult<AxiosResponse<getProduction>, Error> => {
  return useQuery(["productions", params], () => getProductions(params), {
    retry: 1,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

const getProductionById = (
  id: number
): Promise<AxiosResponse<ProductionDetailResponse>> => {
  return requestToCastingVote(`productions/${id}`);
};

export const useProductionDetail = (
  id: number
): UseQueryResult<AxiosResponse<ProductionDetailResponse>, Error> => {
  return useQuery(["productionDetail", id], () => getProductionById(id), {
    retry: 1,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

const createCastingCall = (
  data: CreateCastingCallData
): Promise<AxiosResponse<CreateCastingCallResponse>> => {
  return requestToCastingVote.post("castingcalls", data);
};

export const useCreateCastingCallMutation = (): UseMutationResult<
  AxiosResponse<CreateCastingCallResponse>,
  AxiosError,
  CreateCastingCallData
> => {
  return useMutation(createCastingCall, {
    onSuccess: (response) => {
      if (response.data.code === 200) {
        alert("공고 등록이 성공적으로 완료되었습니다.");
      } else {
        alert("공고 등록 실패: " + response.data.message);
      }
    },
    onError: (error) => {
      console.error("공고 등록 중 오류 발생:", error.response?.data);
    },
  });
};

const getProductionDetails = (id: number) => {
  return requestToCastingVote({
    method: "GET",
    url: `/productions/${id}`,
  });
};

export const useProductionDetails = (
  id: number
): UseQueryResult<AxiosResponse<EditProductionResponseData>, Error> => {
  return useQuery<AxiosResponse<EditProductionResponseData>, Error>(
    ["productionDetails", id], // Query key
    () => getProductionDetails(id), // Query function
    {
      retry: 1,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};


const updateProduction = async (data: UpdateProductionRequest): Promise<AxiosResponse<UpdateProductionResponse>> => {
  return requestToCastingVote.patch('productions', data);
};

export const useUpdateProduction = () => {
  return useMutation<AxiosResponse<UpdateProductionResponse>, Error, UpdateProductionRequest>(
    updateProduction
  );
};