import useOutSideClick from "hooks/onOutSideClick";
import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

interface BottomPopupProps {
  className?: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BottomPopupContainer({ children }: { children: React.ReactNode }) {
  return createPortal(<>{children}</>, document.getElementById("bottomPopup")!);
}

const BottomPopup = ({ children, onClose, className }: BottomPopupProps) => {
  const bottomPopupRef = useRef(null);

  const handleClose = () => {
    onClose?.();
  };

  useOutSideClick(bottomPopupRef, handleClose);


  return (
    <BottomPopupContainer>
        <div className="fixed top-0 z-50 left-0 w-full h-full mx-auto bg-[#000] opacity-40" />
        <div
          ref={bottomPopupRef}
          className={`fixed left-0 animate-bottom transition-all bg-Gray00 right-0 bottom-0 z-[100] w-full max-w-[400px] mx-auto rounded-t-[20px] box-border pb-12 p-5 flex-col flex ${className}`}
        >
          {children}
        </div>
    </BottomPopupContainer>
  );
};

export default BottomPopup;
