import { useSignupMutation } from "api/auth";
import { useImageUpload } from "api/upload";
import Button from "components/Button";
import DateInput from "components/DateInput";
import ImgUpload from "components/ImgUpload";
import Input from "components/Input";
import Radio from "components/Radio";
import TitleWrap from "components/TitleWrap";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import requestToCastingVote from "utils/requestToCastingVote";
import { ReactComponent as IconPlus } from "assets/icon_plus.svg";

const Signup = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedType, setSelectedType] = useState("");
  const [currentBizFile, setCurrnetBizFile] = useState({
    fileName: "",
    id: 0,
  });
  const [selectedGender, setSelectedGender] = useState("");
  const [currentStep, setCurrentStep] = useState(1);

  const { state } = location;

  const [passCheck, setPassCheck] = useState("");
  const [data, setData] = useState({
    userId: "",
    password: "",
    name: "",
    phoneNumber: "",
    email: "",
    gender: "",
    birthDate: "",
    zonecode: "",
    roadAddress: "",
    jibunAddress: "",
    sido: "",
    sigungu: "",
    termsOfService: false,
    termsOfServiceAt: "",
    privacyPolicy: false,
    privacyPolicyAt: "",
    thirdPartySharing: false,
    thirdPartySharingAt: "",
    marketingOptIn: false,
    marketingOptInAt: "",
    recommendId: "",
    role: "",
    businessName: "",
    businessNumber: "",
    businessLicenseId: 0,
  });

  useEffect(() => {
    setData((pre) => ({
      ...pre,
      ...state,
    }));
  }, [state]);

  const totalStep = 4;

  const staffJop = [
    { title: "캐스팅 디렉터", id: "CASTING_DIRECTOR", name: "JOB" },
    { title: "제작자", id: "PRODUCER", name: "JOB" },
    { title: "연출자", id: "DIRECTOR", name: "JOB" },
    { title: "매니저", id: "MANAGER", name: "JOB" },
  ];

  const selectType = (type: string) => {
    if (selectedType === type) {
      setSelectedType("");
    } else {
      setSelectedType(type);
    }
  };

  const getDots = () => {
    const dots = [];
    for (let i = 1; i <= totalStep; i++) {
      dots.push(i <= currentStep ? "blue" : "gray");
    }
    return dots;
  };

  const selectGender = (Gender: string) => {
    if (selectedGender === Gender) {
      setSelectedGender("");
      setData({
        ...data,
        gender: "",
      });
    } else {
      setSelectedGender(Gender);
      setData({
        ...data,
        gender: Gender,
      });
    }
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = (pageNum?: number) => {
    setCurrentStep(currentStep - 1);

    if (pageNum === 2) {
      setData((pre) => ({
        ...pre,
        email: "",
        name: "",
        birthDate: "",
        gender: "",
        role: "",
        businessName: "",
      }));
      setSelectedGender("");
    } else if (pageNum === 3) {
      setData((pre) => ({
        ...pre,
        phoneNumber: "",
      }));
    } else {
      setData((pre) => ({
        ...pre,
        userId: "",
        password: "",
      }));
    }
  };

  const { mutate } = useSignupMutation(data);

  const fileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const formData = new FormData();
    if (files) {
      const fileRef = files[0];
      // console.log(fileRef);
      formData.append("file", fileRef);
      requestToCastingVote({
        method: "POST",
        url: "auth/upload",
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then((res) => {
          setData({
            ...data,
            businessLicenseId: res.data.data.attachmentId,
          });
          setCurrnetBizFile({
            id: res.data.data.attachmentId,
            fileName: fileRef.name,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const infoCheck =
    data.email !== "" &&
    data.name !== "" &&
    data.birthDate !== "" &&
    data.gender !== "";
  const staffInfoCheck =
    data.role !== "" &&
    data.email !== "" &&
    data.birthDate !== "" &&
    data.businessName !== "" &&
    data.businessLicenseId !== 0;
  const phoneCheck = data.phoneNumber !== "";
  const idCheck =
    data.userId !== "" && data.password && data.password === passCheck;

  return (
    <div className="py-20 mx-auto flex flex-col max-w-[520px] justify-between gap-10">
      <div className="flex items-center gap-2">
        {getDots().map((color, i) => {
          return (
            <div
              key={i}
              className={`w-2 h-2 rounded-full ${
                color === "gray" ? "bg-[#D9D9D9]" : "bg-Blue04"
              }`}
            />
          );
        })}
        <div className="w-5 h-6">🏃‍♀️‍</div>
      </div>

      {currentStep === 1 && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            시작하기 전에
            <br />
            직업을 선택해 주세요
          </div>
          <div className="flex flex-col gap-4 mt-10 mb-20">
            <Button
              onClick={() => {
                selectType("ARTIST");
                setData({
                  ...data,
                  role: "ARTIST",
                });
              }}
              className={`w-full ${
                selectedType === "ARTIST"
                  ? "text-Blue04"
                  : "text-[#898A8D] border-[#898A8D]"
              }`}
              text={"아티스트"}
            ></Button>
            <Button
              onClick={() => {
                selectType("STAFF");
              }}
              className={`w-full ${
                selectedType === "STAFF"
                  ? "text-Blue04"
                  : "text-[#898A8D] border-[#898A8D]"
              }`}
              text={"Staff (캐스팅 디렉터, 감독, 프로듀서 등등)"}
            ></Button>
          </div>
          <Button
            onClick={() => {
              handleNextStep();
            }}
            disabled={!selectedType}
            className={`w-full bg-Blue04 text-white`}
            text={"다음"}
          ></Button>
        </div>
      )}
      {currentStep === 2 && selectedType === "ARTIST" && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            환영해요!
            <br />
            기본 정보를 입력해 주세요
          </div>

          <div className="flex flex-col gap-10 mt-10 mb-20">
            <TitleWrap title="이메일">
              <Input
                value={data.email}
                onChange={(e) => {
                  setData({
                    ...data,
                    email: e.target.value,
                  });
                }}
                className="w-full"
                placeholder="이메일을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="이름">
              <Input
                value={data.name}
                onChange={(e) => {
                  setData({
                    ...data,
                    name: e.target.value,
                  });
                }}
                className="w-full"
                placeholder="이름을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="생년월일">
              <DateInput
                value={data.birthDate}
                onChange={(e) => {
                  setData({ ...data, birthDate: e.target.value });
                }}
                className="w-full"
              />
            </TitleWrap>
            <TitleWrap title="성별">
              <div className="flex flex-col-reverse gap-4 w-full">
                <Button
                  onClick={() => {
                    selectGender("FEMALE");
                  }}
                  className={`w-full ${
                    selectedGender === "FEMALE"
                      ? "text-Blue04"
                      : "text-[#898A8D] border-[#898A8D]"
                  }`}
                  text="여성"
                ></Button>
                <Button
                  onClick={() => {
                    selectGender("MALE");
                  }}
                  className={`
                    w-full
                    ${
                      selectedGender === "MALE"
                        ? "text-Blue04"
                        : "text-[#898A8D] border-[#898A8D]"
                    }`}
                  text="남성"
                ></Button>
              </div>
            </TitleWrap>
          </div>
          <div className="flex flex-col-reverse gap-4 w-full">
            <Button
              onClick={() => {
                handlePrevStep(2);
              }}
              className={`w-full border-none bg-[#D9D9D9] text-white`}
              text={"이전"}
            ></Button>
            <Button
              onClick={() => {
                handleNextStep();
              }}
              disabled={!infoCheck}
              className={`w-full bg-Blue04 text-white`}
              text={"다음"}
            ></Button>
          </div>
        </div>
      )}
      {currentStep === 2 && selectedType === "STAFF" && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            환영해요!
            <br />
            기본 정보를 입력해 주세요
          </div>
          <div className="flex flex-col gap-10 mt-10 mb-20">
            <TitleWrap title="직업">
              <div className="flex items-center flex-wrap gap-5">
                {staffJop.map((item, i) => {
                  return (
                    <Radio
                      value={item.id}
                      onChange={(e) => {
                        setData({
                          ...data,
                          role: e.target.value,
                        });
                      }}
                      key={item.id}
                      id={item.id}
                      name={item.name}
                      title={item.title}
                    />
                  );
                })}
              </div>
            </TitleWrap>
            <TitleWrap title="이메일">
              <Input
                value={data.email}
                onChange={(e) => {
                  setData({
                    ...data,
                    email: e.target.value,
                  });
                }}
                placeholder="이메일을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="이름">
              <Input
                value={data.name}
                onChange={(e) => {
                  setData({
                    ...data,
                    name: e.target.value,
                  });
                }}
                placeholder="이름을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="생년월일">
              <DateInput
                value={data.birthDate}
                onChange={(e) => {
                  setData({ ...data, birthDate: e.target.value });
                }}
                className="w-full"
              />
            </TitleWrap>
            <TitleWrap title="소속사">
              <Input
                value={data.businessName}
                onChange={(e) => {
                  setData({
                    ...data,
                    businessName: e.target.value,
                  });
                }}
                placeholder="소속회사를 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="사업자등록증">
              <ImgUpload
                onChange={(e) => {
                  fileUpload(e);
                }}
                id={"bizFile"}
              >
                <div className="border border-[#D9D9D9] border-dashed text-center text-Blue04 py-4 MBody16">
                  <div>사업자 등록증 업로드</div>
                  <IconPlus className="mx-auto mt-4" />
                </div>
              </ImgUpload>
              {currentBizFile && currentBizFile.fileName && (
                <div className="flex items-center justify-between">
                  <div>{currentBizFile?.fileName}</div>
                  <div
                    onClick={() => {
                      setCurrnetBizFile({
                        fileName: "",
                        id: 0,
                      });
                      setData({ ...data, businessLicenseId: 0 });
                    }}
                  >
                    X
                  </div>
                </div>
              )}
            </TitleWrap>
          </div>
          <div className="flex flex-col-reverse gap-4 w-full">
            <Button
              onClick={() => {
                handlePrevStep(2);
              }}
              className={`w-full border-none bg-[#D9D9D9] text-white`}
              text={"이전"}
            ></Button>
            <Button
              onClick={() => {
                handleNextStep();
              }}
              disabled={!staffInfoCheck}
              className={`w-full bg-Blue04 text-white`}
              text={"다음"}
            ></Button>
          </div>
        </div>
      )}
      {currentStep === 3 && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            거의 다 왔어요!
            <br />
            휴대폰 인증을 시작할게요
          </div>

          <div className="flex flex-col gap-10 mt-10 mb-20">
            <TitleWrap title="휴대폰번호">
              <div className="flex items-center gap-2">
                <Input
                  value={data.phoneNumber}
                  onChange={(e) => {
                    setData({
                      ...data,
                      phoneNumber: e.target.value,
                    });
                  }}
                  className="w-[424px]"
                  placeholder="-없이 숫자만 입력해 주세요"
                  type={"text"}
                ></Input>
                <Button
                  className="w-[88px] text-Blue04 !MBody16"
                  text="인증요청"
                ></Button>
              </div>
            </TitleWrap>
            <TitleWrap title="휴대폰번호 인증">
              <div className="flex items-center gap-2">
                <Input
                  className="w-[424px]"
                  placeholder="인증번호를 입력해 주세요"
                  type={"text"}
                ></Input>
                <Button
                  className="w-[88px] text-[#898A8D] border !MBody16 border-[#D9D9D9]"
                  text="인증확인"
                ></Button>
              </div>
            </TitleWrap>
          </div>
          <div className="flex flex-col-reverse gap-4 w-full">
            <Button
              onClick={() => {
                handlePrevStep(3);
              }}
              className={`w-full border-none bg-[#D9D9D9] text-white`}
              text={"이전"}
            ></Button>
            <Button
              disabled={!phoneCheck}
              onClick={() => {
                handleNextStep();
              }}
              className={`w-full bg-Blue04 text-white`}
              text={"다음"}
            ></Button>
          </div>
        </div>
      )}
      {currentStep === 4 && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            아이디와 비밀번호를
            <br />
            입력해 주세요
          </div>
          <div className="flex flex-col BBody18 gap-10 mt-10 mb-20">
            <div className="flex flex-col gap-4">
              <div>아이디</div>
              <Input
                value={data.userId}
                onChange={(e) => {
                  setData({
                    ...data,
                    userId: e.target.value,
                  });
                }}
                className="w-[520px]"
                placeholder="아이디를 6자 이상으로 설정해 주세요"
                type={"text"}
              ></Input>
            </div>
            <div className="flex flex-col gap-4">
              <div>비밀번호</div>
              <Input
                value={data.password}
                onChange={(e) => {
                  setData({
                    ...data,
                    password: e.target.value,
                  });
                }}
                className="w-[520px]"
                placeholder="알파벳, 숫자, 특수문자 포함 8자 이상으로 설정해 주세요"
                type={"password"}
              ></Input>
            </div>
            <div className="flex flex-col gap-4">
              <div>비밀번호 확인</div>
              <Input
                onChange={(e) => {
                  setPassCheck(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    mutate(data, {
                      onSuccess: (data) => {
                        console.log(data);
                        if (data.data.code === 200) {
                          alert("회원가입 성공");
                          navigate("/login");
                        }
                      },
                      onError: (err) => {
                        if (err?.response?.data.code !== 200) {
                          alert("에러가 발생했습니다. 빈칸 없이 채워주세요");
                        }
                      },
                    });
                  }
                }}
                value={passCheck}
                className="w-[520px]"
                placeholder="동일한 비밀번호를 입력해 주세요"
                type={"password"}
              ></Input>
            </div>
          </div>
          <div className="flex flex-col-reverse gap-4">
            <Button
              onClick={() => {
                handlePrevStep(4);
              }}
              className={`w-full border-none bg-[#D9D9D9] text-white`}
              text={"이전"}
            ></Button>
            <Button
              onClick={() => {
                mutate(data, {
                  onSuccess: (data) => {
                    console.log(data);
                    if (data.data.code === 200) {
                      alert("회원가입 성공");
                      navigate("/login");
                    }
                  },
                  onError: (err) => {
                    if (err?.response?.data.code !== 200) {
                      alert("에러가 발생했습니다. 빈칸 없이 채워주세요");
                    }
                  },
                });
              }}
              // disabled={!idCheck}
              className={`w-full bg-Blue04 text-white`}
              text={"완료"}
            ></Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
