import React from "react";

interface ButtonType {
  text: string;
  className?: string;
  onClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

const Button = ({
  text,
  className,
  onClick,
  onKeyDown,
  disabled,
}: ButtonType) => {
  return (
    <div>
      <button
        onKeyDown={onKeyDown}
        disabled={disabled}
        onClick={onClick}
        className={`disabled:cursor-default py-3 text-center box-border rounded-lg disabled:border-none disabled:bg-Blue04/20 border border-Blue04 cursor-pointer ${className}`}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;
