import React, { useEffect, useRef, useState } from "react";
import ContentWrap from "components/ContentWrap";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import { ReactComponent as Share } from "../assets/share.svg";
import Button from "components/Button";

const CastingDetail = () => {
  const stickyRef = useRef<HTMLDivElement | null>(null);
  const [isSticky, setSticky] = useState(false);
  

  // const handleScroll = () => {
  //   if (!stickyRef.current) return;
  //   const stickyTop = stickyRef.current.getBoundingClientRect().top;
  //   const limit = 300;

  //   if (window.scrollY >= limit) {
  //     setSticky(true);
  //   } else {
  //     setSticky(false);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const castingInfo = [
    {
      id: 0,
      Title: "분야",
      Desc: "영화",
    },
    {
      id: 1,
      Title: "감독",
      Desc: "김감독",
    },
    {
      id: 2,
      Title: "제작사",
      Desc: "스튜디오드래곤",
    },
    {
      id: 3,
      Title: "구분",
      Desc: "주연",
    },
    {
      id: 4,
      Title: "모집인원",
      Desc: "1명",
    },
    {
      id: 5,
      Title: "성별",
      Desc: "여성",
    },
    {
      id: 6,
      Title: "나이",
      Desc: "20대",
    },
    {
      id: 7,
      Title: "촬영지역",
      Desc: "서울시 중구",
    },
    {
      id: 8,
      Title: "촬영날짜",
      Desc: "2024.09.30",
    },
    {
      id: 9,
      Title: "출연료",
      Desc: "300,000원",
    },
    {
      id: 10,
      Title: "출연회차",
      Desc: "미정",
    },
    {
      id: 11,
      Title: "캐스팅담당자",
      Desc: "김캐디",
    },
  ];

  const castingDesc = [
    {
      id: 0,
      title: "상세내용",
      desc: "상세내용",
    },
    {
      id: 1,
      title: "자격요건",
      desc: "자격요건",
    },
    {
      id: 2,
      title: "우대사항",
      desc: "우대사항",
    },
    {
      id: 3,
      title: "모집기간",
      desc: "모집기간",
    },
  ];

  return (
    <ContentWrap className="pb-24">
      <div className="mt-4">
        <div className="BBody20 ">
          옴니버스 장편영화중 공포 스릴러 1편 여성 단독주연 하실 20대 여성분 1분
          구합니다. [9월말 10월초 1~2회차 촬영]
        </div>
        <div className="flex justify-between items-center my-4 BBody18">
          <div>김캐디</div>
          <div className="flex items-center gap-2">
            <Bookmark />
            <Share />
          </div>
        </div>
        <div className=" flex flex-col mb-10 items-start gap-4">
          <div className="w-full h-[360px] border-black border rounded-[10px]" />
          <div className="w-full">
            <div>
              <div className="bg-[#F9FAFA] w-full rounded-[10px] p-4 flex flex-col gap-2">
                {castingInfo.map((item) => {
                  return (
                    <div key={item.id}>
                      <div className="flex gap-[39px] items-center BBody14">
                        <div className="flex items-center w-[73px] text-[#898A8D]">
                          {item.Title}
                        </div>
                        <div className="text-Gray09 w-[158px]">{item.Desc}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-10 w-full">
          {castingDesc.map((item) => {
            return (
              <div key={item.id}>
                <div className="BBody18">{item.title}</div>
                <div className="RBody16 mt-4 whitespace-pre-wrap">
                  {item.desc}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 border-t border-Gray03 mt-5 py-4 px-5">
        <Button
          className="bg-Blue04 w-full py-3 MBody16 text-white border-none"
          text={"지원하기"}
        ></Button>
      </div>
    </ContentWrap>
  );
};

export default CastingDetail;
