import React from "react";

const CastingCard = () => {
  return (
    <div className="w-[101px] h-[138px] bg-center bg-gradient-to-b p-4 border bg-[url(./assets/testImg2.png)] border-black rounded-[10px] relative">
      <div className="absolute text-white bottom-4 left-4">
        <div className="mb-2 RCaption10">영화</div>
        <div className="BBody14 max-w-24 textHidden">
          단편영화제 외국인 배우 구합니다 (동남아, 유럽, 아시아 등)
        </div>
      </div>
    </div>
  );
};

export default CastingCard;
