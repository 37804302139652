import React from 'react';

interface RadioType {
    name: string;
    title: string;
    id: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio = ({name, title, id, value, onChange}:RadioType) => {
    return (
        <div className="flex items-center me-2">
            <input onChange={onChange} id={id} type="radio" name={name} value={value} className="appearance-none w-[18px] h-[18px] bg-radioCheck checked:bg-radioChecked bg-no-repeat bg-center"/>
            <label htmlFor={id} className="ms-2 RBody16 text-gray-900">{title}</label>
        </div>
    );
};

export default Radio;