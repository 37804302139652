import React, { useState, useEffect } from "react";

interface RangeInputType {
  fixedMinPrice: number;
  fixedMaxPrice: number;
  priceGap: number;
}

const RangeInput = ({
  fixedMinPrice,
  fixedMaxPrice,
  priceGap,
}: RangeInputType) => {
  const [rangeMinValue, setRangeMinValue] = useState(fixedMinPrice);
  const [rangeMaxValue, setRangeMaxValue] = useState(fixedMaxPrice);
  const [rangeMinPercent, setRangeMinPercent] = useState(0);
  const [rangeMaxPercent, setRangeMaxPercent] = useState(0);

  useEffect(() => {
    if (rangeMaxValue - rangeMinValue < priceGap) {
      setRangeMaxValue(rangeMinValue + priceGap);
    } else {
      setRangeMinPercent((rangeMinValue / fixedMaxPrice) * 100);
      setRangeMaxPercent(100 - (rangeMaxValue / fixedMaxPrice) * 100);
    }
  }, [rangeMinValue, rangeMaxValue, priceGap, fixedMaxPrice]);

  const prcieRangeMinValueHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(e.target.value);
    if (value + priceGap <= rangeMaxValue) {
      setRangeMinValue(value);
    }
  };

  const prcieRangeMaxValueHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(e.target.value);
    if (value - priceGap >= rangeMinValue) {
      setRangeMaxValue(value);
    }
  };

  return (
    <div className="relative border bg-Gray03 flex w-[301px] rounded-full h-1 box-content">
      <input
        min={fixedMinPrice}
        max={fixedMaxPrice - priceGap}
        value={rangeMinValue}
        onChange={(e) => prcieRangeMinValueHandler(e)}
        className="rangeBar"
        type="range"
      />
      <input
        min={fixedMinPrice + priceGap}
        max={fixedMaxPrice}
        value={rangeMaxValue}
        onChange={(e) => prcieRangeMaxValueHandler(e)}
        className="rangeBar"
        type="range"
      />
      <div
        style={{
          left: `${rangeMinPercent}%`,
          right: `${rangeMaxPercent}%`,
        }}
        className="absolute h-1 rounded-full bg-Blue04"
      ></div>
    </div>
  );
};

export default RangeInput;
