import React from "react";
import ContentWrap from "components/ContentWrap";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import CastingRecruitCard from "components/CastingRecruitCard";
import { useNavigate, useParams } from "react-router-dom";
import { useProductionDetail } from "api/staff";

const StaffFilmoDetail = () => {
  const navigate = useNavigate();
  const { staffId } = useParams();

  const { data } = useProductionDetail(Number(staffId));

  const filmoData = data?.data.data;

  const filmInfo = [
    {
      id: 0,
      title: "구분",
      desc: filmoData?.format.label,
    },
    {
      id: 1,
      title: "감독",
      desc: filmoData?.directorName,
    },
    {
      id: 2,
      title: "제작사",
      desc: filmoData?.companyName,
    },
    {
      id: 3,
      title: "캐스팅담당",
      desc: filmoData?.castingDirectorName,
    },
  ];
  return (
    <ContentWrap className="pb-20 pt-4">
      <img
        src={`https://test.castingvote.tv/uploads${filmoData?.thumbnailUrl}`}
        className="w-full object-cover rounded-[10px] h-[194px]"
        alt="filmoDetailImage"
      />
      <div>
        <div className="my-4 mb-10 flex-col flex items-start gap-5">
          <div className="flex-1">
            <div className="flex items-center gap-2">
              <div className="BBody20">{filmoData?.title}</div>
              <Bookmark />
            </div>
            <div className="mt-4 RBody16 whitespace-pre-wrap">
              {filmoData?.description}
            </div>
          </div>
          <div className="w-full flex-col flex BBody14 rounded-[10px] gap-3 bg-[#F5F5F5] p-4">
            {filmInfo.map((item) => {
              return (
                <div key={item.id} className="flex items-center gap-4">
                  <div className="w-[118px] text-[#898A8D]">{item.title}</div>
                  <div className="flex-1">{item.desc}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <div className="BBody18 mb-4">모집중인 캐스팅</div>
          <div className="flex flex-wrap gap-4">
            <CastingRecruitCard
              onClick={() => {
                navigate("/casting/3");
              }}
            />
            <CastingRecruitCard />
            <CastingRecruitCard />
            <CastingRecruitCard />
            <CastingRecruitCard />
          </div>
        </div>
      </div>
    </ContentWrap>
  );
};

export default StaffFilmoDetail;
