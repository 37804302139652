import requestToCastingVote from "./requestToCastingVote";

export const onLogOut = () => {
  requestToCastingVote
    .post("auth/logout")
    .then((res) => {
      if (res.status === 200) {
        localStorage.clear();
        window.location.href = "/";
        return;
      }
    })
    .catch((err) => {
      localStorage.removeItem("user");
    });
};
