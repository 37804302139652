import React from "react";
import castingOn from "assets/castingOn.png";
import castingOff from "assets/castingOff.png";
import homeOn from "assets/homeOn.png";
import homeOff from "assets/homeOff.png";
import staffOn from "assets/staffOn.png";
import staffOff from "assets/staffOff.png";
import artistOn from "assets/artistOn.png";
import artistOff from "assets/artistOff.png";
import { NavLink, useLocation, useParams } from "react-router-dom";
import ContentWrap from "./ContentWrap";
import { useRecoilState } from "recoil";
import { loginCheck } from "store/auth/atom";

const TabBar = () => {
  const location = useLocation();
  const params = useParams();
  const [login, setLogin] = useRecoilState(loginCheck);

  const tabBarItem = [
    { id: 0, name: "홈", url: "/", icon: homeOff, iconOn: homeOn },
    {
      id: 1,
      name: "캐스팅",
      url: "/casting",
      icon: castingOff,
      iconOn: castingOn,
    },
    {
      id: 2,
      name: "아티스트",
      url: "/artist",
      icon: artistOff,
      iconOn: artistOn,
    },
    { id: 3, name: "STAFF", url: "/staff", icon: staffOff, iconOn: staffOn },
    { id: 4, name: `${login ? "더보기": "로그인"}`, url: `${login ? "/my": "/login"}`, icon: "", iconOn: "" },
  ];

  if (location.pathname === "/artist/profile") return null;
  if (location.pathname === "/artist/my") return null;
  if (location.pathname === `/casting/${params?.itemId}`) return null;
  if (location.pathname === `/artist/${params?.artistId}`) return null;
  if (location.pathname === `/staff/${params?.staffId}`) return null;
  if (location.pathname === `/staff/profile`) return null;
  if (location.pathname === `/staff/post`) return null;
  if (location.pathname === `/staff/casting-call`) return null;

  return (
    <ContentWrap className="fixed pb-[10px] w-full left-2/4 z-50 bg-white -translate-x-2/4 pt-2 bottom-0 border rounded-tr-[20px] rounded-tl-[20px]">
      <div className="flex items-center gap-1.5 justify-between RBody14">
        {tabBarItem.map((item) => {
          // const IconComponent = item.icon;
          return (
            <NavLink
              key={item.id}
              className={({ isActive }) =>
                `${
                  isActive ? "text-Gray09" : "text-Gray04"
                } flex flex-col items-center gap-1.5 mx-auto`
              }
              to={`${item.url}`}
            >
              <img alt="" src={item.icon} />
              <div>{item.name}</div>
            </NavLink>
          );
        })}
      </div>
    </ContentWrap>
  );
};

export default TabBar;
