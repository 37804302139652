import React, { useState } from "react";

import ContentWrap from "components/ContentWrap";
import NoticeCard from "components/NoticeCard";
import SearchBox from "components/SearchBox";
import WorkCard from "components/WorkCard";
import { useNavigate } from "react-router-dom";
import useDebounce from "hooks/useDebounce";
import Chip from "components/Chip";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import TitleWrap from "components/TitleWrap";
import Button from "components/Button";
import SideTab from "components/SideTab";
import { useProductions } from "api/staff";

const CastingList = () => {
  const navigate = useNavigate();
  const [castingType, setCastingType] = useState("notice");
  const [search, setSearch] = useState<string>("");
  const [isSideTabOpen, setIsSideTabOpen] = useState(false);

  const handleOpenTab = () => setIsSideTabOpen(true);
  const handleCloseTab = () => setIsSideTabOpen(false);

  const debouncedSearchText = useDebounce(search, 500);

  const castType = [
    { name: "공고보기", type: "notice" },
    { name: "작품보기", type: "work" },
  ];

  const { data: noticeData } = useProductions({
    title: debouncedSearchText,
    format: "",
    directorName: "",
    companyName: "",
  });

  const format = [
    "드라마",
    "영화",
    "예능",
    "광고",
    "뮤직비디오",
    "웹드라마",
    "숏폼",
    "독립영화",
  ];
  const keyword = ["주연", "조연", "단역", "이미지단역", "보조출연", "대역"];
  const gender = ["여성", "남성"];

  return (
    <ContentWrap>
      <SideTab isOpen={isSideTabOpen} onClose={handleCloseTab}>
        <div className="pl-2 p-4 flex items-center justify-between">
          <div
            onClick={handleCloseTab}
            className="MBody18 cursor-pointer flex items-center"
          >
            <ArrowLeft />
            <div>필터</div>
          </div>
          <div className="BBody18 cursor-pointer text-Blue04">초기화</div>
        </div>
        <div className="pt-6 pl-4 pb-[100px] flex flex-col items-start gap-10">
          <TitleWrap title="분류" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {format.map((item, i) => {
                return <Chip key={i} title={item}></Chip>;
              })}
            </div>
          </TitleWrap>
          <TitleWrap title="배역" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {keyword.map((item, i) => {
                return <Chip key={i} title={item}></Chip>;
              })}
            </div>
          </TitleWrap>
          <TitleWrap title="성별" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {gender.map((item, i) => {
                return <Chip key={i} title={item}></Chip>;
              })}
            </div>
          </TitleWrap>
        </div>
        <div className="fixed pb-[19px] bottom-0 px-4">
          <Button
            className="w-[320px] border-none bg-Blue04 text-white"
            text={"필터 적용하기"}
          ></Button>
        </div>
      </SideTab>
      <div className="w-full">
        <div className="my-4 wflex items-center justify-between">
          <SearchBox
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            className="w-full"
            placeholder="작품 또는 캐스팅 공고를 입력해 주세요"
          />
        </div>
        <div className="flex my-4 items-center w-full justify-between">
          <div className="flex items-center gap-2">
            {castType.map((item, i) => {
              return (
                <div
                  key={i}
                  className={`${
                    castingType === item.type
                      ? "BBody16 text-Blue04"
                      : "MBody16"
                  } cursor-pointer`}
                  onClick={() => {
                    setCastingType(item.type);
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <Chip onClick={handleOpenTab} title={"필터"}></Chip>
        </div>
      </div>
      {castingType === "notice" ? (
        <div
          // style={{
          //   gridTemplateColumns: "1fr 1fr",
          //   gridTemplateRows: "repeat(2, 174px)",
          // }}
          className="flex flex-col pb-20 gap-4"
        >
          <NoticeCard/>
          <NoticeCard image="d" />
          <NoticeCard />
          <NoticeCard />
          <NoticeCard />
          <NoticeCard />
          <NoticeCard />
          <NoticeCard />
        </div>
      ) : (
        <div className="flex flex-col pb-20 gap-4">
          {noticeData?.data.data.content.map((item, i) => {
            return (
              <WorkCard
                title={item.title}
                onClick={() => {
                  navigate(`/staff/filmo/${item.productionId}`);
                }}
                src={item.thumbnailUrl}
                format={item.format.label}
                company={item.companyName}
                manager={item.castingDirectorName}
              />
            );
          })}
        </div>
      )}
    </ContentWrap>
  );
};

export default CastingList;
