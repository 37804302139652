import Button from "components/Button";
import Chip from "components/Chip";
import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useDidMountEffect } from "hooks/useDidMountEffect";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { onLogOut } from "utils/onLogOut";
import { info, loginCheck } from "store/auth/atom";

const Mypage = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useRecoilState(loginCheck); 

  const [userInfo, setUserInfo] = useState<{
    id: string;
    username: string;
    role: string;
  }>({ id: "", username: "", role: "" });

  useEffect(() => {
    const updatedUserInfo = localStorage.getItem("user");

    let userInfo = updatedUserInfo ? JSON.parse(updatedUserInfo) : null;
    if (userInfo && userInfo.id) {
      setLogin(true);
      setUserInfo({
        id: userInfo.id,
        username: userInfo.username,
        role: userInfo.role,
      });
    } else {
      setLogin(false);
    }
  }, []);

  return (
    <ContentWrap>
      <HeaderWithBackButton title="내 프로필" />
      <div className="py-20">
        {login ? (
          <div>
            <div className="flex items-center gap-4">
              <div className="w-12 h-12 rounded-full bg-red-100" />
              <div className="BBody20 text-Gray09">{userInfo?.username}</div>
            </div>
            <div className="mt-10 flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <div className="MBody18">포인트</div>
                <div className="flex items-center gap-2">
                  <div className="text-Blue04 !BBody18">0</div>
                  <Chip selected title="충전"></Chip>
                </div>
              </div>
              <div className="MBody18">충전내역</div>
              <div className="MBody18">사용내역</div>
            </div>
            <hr className="w-full h-px bg-Gray03 my-4" /> 
            {userInfo?.role === "ARTIST" ? (
              <div
                onClick={() => {
                  navigate(`/artist/my`);
                }}
                className="MBody18 cursor-pointer"
              >
                내프로필
              </div>
            ) : (
              <div onClick={() => {
                navigate(`/staff/casting/my`);
              }} className="MBody18 cursor-pointer">내 캐스팅</div>
            )}
            <hr className="w-full h-px bg-Gray03 my-4" />
            {userInfo?.role === "ARTIST" ? (
              <div className="flex flex-col MBody18 gap-4">
                <div className="cursor-pointer">프로필 피드백</div>
                <div className="cursor-pointer">캐스팅 지원내역</div>
                <div className="cursor-pointer">받은 제안</div>
                <div className="cursor-pointer">스크랩</div>
              </div>
            ) : (
              <div className="flex flex-col MBody18 gap-4">
                <div className="cursor-pointer">프로필 피드백</div>
                <div className="cursor-pointer">보낸 제안</div>
                <div className="cursor-pointer">스크랩</div>
              </div>
            )}
            <hr className="w-full h-px bg-Gray03 my-4" />
            <div className="flex MBody18 flex-col gap-4">
              <div className="cursor-pointer">내정보</div>
              {userInfo.role !== "ARTIST" && (
                <div
                  onClick={() => {
                    navigate(`/staff/my`);
                  }}
                  className="cursor-pointer"
                >
                  내프로필
                </div>
              )}
              <div className="cursor-pointer">고객센터</div>
              <div
                className="cursor-pointer text-Gray05"
                onClick={() => {
                  onLogOut();
                  navigate("/");
                  setLogin(false);
                }}
              >
                로그아웃
              </div>
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              navigate("/login");
            }}
          >
            로그인 하러 가기
          </div>
        )}
      </div>
    </ContentWrap>
  );
};

export default Mypage;
