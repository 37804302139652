import React from "react";
import test1 from "../assets/testImg.png";

interface ArtistCardType {
  artistName: string;
  artistJop?: string;
  artistSize?: string;
  onClick?: () => void;
  src?: string;
}

const ArtistCard = ({
  artistName,
  artistJop,
  src,
  artistSize,
  onClick,
}: ArtistCardType) => {
  return (
    <div>
      <img
        src={`https://test.castingvote.tv/uploads${src}`}
        alt="Thumnail"
        onClick={onClick}
        className="object-cover w-[150px] h-[150px] cursor-pointer rounded-[10px]"
      />
      <div className="flex gap-2 mt-4 mb-2 items-center">
        <div className="BBody16">{artistName}</div>
        <div className="BBody14 text-[#898A8D]">{artistJop}</div>
      </div>
      {artistSize && <div className="MBody16 text-[#898A8D]">170/54</div>}
    </div>
  );
};

export default ArtistCard;
