import { AxiosError, AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { ArtistDetailData, ArtistList,  ArtistListData, CreateArtistProfile, CreateArtistProfileData, ProfileUpdate } from "type/artist";
import requestToCastingVote from "utils/requestToCastingVote";

const createArtist = ({
  name,
  gender,
  job,
  zonecode,
  roadAddress,
  jibunAddress,
  sido,
  sigungu,
  birthDate,
  height,
  weight,
  shoeSize,
  bust,
  waist,
  hip,
  education,
  rank,
  salary,
  agency,
  introduce,
  instagram,
  youtube,
  facebook,
  twitter,
  tiktok,
  linkedin,
  hashtags,
  hobbies,
  filmographies,
  attachments,
  videoLinks,
}: CreateArtistProfile) => {
  return requestToCastingVote({
    method: "POST",
    url: "profiles/artist",
    data: {
      name: name,
      gender: gender,
      job: job,
      zonecode: zonecode,
      roadAddress: roadAddress,
      jibunAddress: jibunAddress,
      sido: sido,
      sigungu: sigungu,
      birthDate: birthDate,
      height: height,
      weight: weight,
      shoeSize: shoeSize,
      bust: bust,
      waist: waist,
      hip: hip,
      education: education,
      rank: rank,
      salary: salary,
      agency: agency,
      introduce: introduce,
      instagram: instagram,
      youtube: youtube,
      facebook: facebook,
      twitter: twitter,
      tiktok: tiktok,
      linkedin: linkedin,
      hashtags: hashtags,
      hobbies: hobbies,
      filmographies: filmographies,
      attachments: attachments,
      videoLinks: videoLinks,
    },
  });
};

export const useCreateArtistProfileMutation = (
  data: CreateArtistProfile
): UseMutationResult<AxiosResponse<CreateArtistProfileData>, Error, CreateArtistProfile> => {
  const createArtistMutation = useMutation<
    AxiosResponse<any>,
    AxiosError,
    CreateArtistProfile
  >({
    mutationFn: createArtist,
    onSuccess: (data, variables, context) => {
      if (data.data.code === 200) {
        // navigate("/")
        alert('성공')
      } else {
        alert('실패')
      }
    },
    // onSettled: () => {
    //   console.log("Mutation settled");
    // },
    onError: (error) => {
      console.error(error.response?.data);
    },
  });

  return createArtistMutation;
};

const getArtistList = ({
  name,
  gender,
  job,
  ageGoe,
  ageLoe,
  heightGoe,
  heightLoe,
  weightGoe,
  weightLoe,
  hashtagIds,
  hobbyIds,
  page,
  size,
}: ArtistList) => {
  return requestToCastingVote({
    method: "GET",
    url: "profiles/artist",
    params: {
      name: name,
      gender: gender,
      job: job,
      ageGoe: ageGoe,
      ageLoe: ageLoe,
      heightGoe: heightGoe,
      heightLoe: heightLoe,
      weightGoe: weightGoe,
      weightLoe: weightLoe,
      hashtagIds: hashtagIds,
      hobbyIds: hobbyIds,
      page: page,
      size: size,
    },
  });
};

export const useArtistList = (
  params: ArtistList
): UseQueryResult<AxiosResponse<ArtistListData>, Error> =>
  useQuery(["useArtistList", params], () => getArtistList(params), {
    retry: 2,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

const getArtistDetail = (id : number) => {
  return requestToCastingVote({
    method: "GET",
    url: `profiles/${id}/artist`,
  })
};


export const useArtistDetail = (
  id: number
): UseQueryResult<AxiosResponse<ArtistDetailData>, Error> =>
  useQuery(["useArtistDetail"], () => getArtistDetail(id), {
    retry: 1,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });


  const updateArtist = ({
    profileId,
    name,
    gender,
    job,
    zonecode,
    roadAddress,
    jibunAddress,
    sido,
    sigungu,
    birthDate,
    height,
    weight,
    shoeSize,
    bust,
    waist,
    hip,
    education,
    rank,
    salary,
    agency,
    introduce,
    instagram,
    youtube,
    facebook,
    twitter,
    tiktok,
    linkedin,
    addHashtags,
    removeHashtagIds,
    addHobbies,
    removeHobbyIds,
    addAttachments,
    addFilmographies,
    removeFilmographyIds,
    addVideoLinks,
    removeVideoLinks,
  }: ProfileUpdate) => {
    return requestToCastingVote({
      method: "PATCH",
      url: "profiles",
      data: {
        profileId:profileId,
        name: name,
        gender: gender,
        job: job,
        zonecode: zonecode,
        roadAddress: roadAddress,
        jibunAddress: jibunAddress,
        sido: sido,
        sigungu: sigungu,
        birthDate: birthDate,
        height: height,
        weight: weight,
        shoeSize: shoeSize,
        bust: bust,
        waist: waist,
        hip: hip,
        education: education,
        rank: rank,
        salary: salary,
        agency: agency,
        introduce: introduce,
        instagram: instagram,
        youtube: youtube,
        facebook: facebook,
        twitter: twitter,
        tiktok: tiktok,
        linkedin: linkedin,
        addHashtags,
        removeHashtagIds,
        addHobbies,
        addAttachments,
        removeHobbyIds,
        addFilmographies,
        removeFilmographyIds,
        addVideoLinks,
        removeVideoLinks,
      },
    });
  };
  
  export const useUpdateArtistProfileMutation = (
    data: ProfileUpdate
  ): UseMutationResult<AxiosResponse<any>, Error, ProfileUpdate> => {
    const udateArtistMutation = useMutation<
      AxiosResponse<any>,
      AxiosError,
      ProfileUpdate
    >({
      mutationFn: updateArtist,
      onSuccess: (data, variables, context) => {
        if (data.data.code === 200) {
          // navigate("/")
          alert('성공')
        } else {
          alert('실패')
        }
      },
      // onSettled: () => {
      //   console.log("Mutation settled");
      // },
      onError: (error) => {
        console.error(error.response?.data);
      },
    });
  
    return udateArtistMutation;
  };