import React, { useEffect, useState } from "react";

import SearchBox from "components/SearchBox";
import { useNavigate } from "react-router-dom";
import ProfileInfoCard from "components/ProfileInfoCard";
import ContentWrap from "components/ContentWrap";
import { useArtistList } from "api/artist";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import useDebounce from "hooks/useDebounce";
import { ArtistList as ArtistListType } from "type/artist";
import Chip from "components/Chip";
import SideTab from "components/SideTab";
import TitleWrap from "components/TitleWrap";
import Button from "components/Button";
import RangeInput from "components/RangeInput";

const ArtistList = () => {
  const navigate = useNavigate();
  const [isSideTabOpen, setIsSideTabOpen] = useState(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearchText = useDebounce(search, 500);
  const [hashtagIds, setHashtagIds] = useState([]);
  const [artistData, setArtistData] = useState<ArtistListType>({
    gender: "",
    job: "",
    ageGoe: "",
    ageLoe: "",
    heightGoe: "",
    heightLoe: "",
    weightGoe: "",
    weightLoe: "",
    hashtagIds: [],
    hobbyIds: [],
    page: 0,
    size: 20,
  });

  const handleOpenTab = () => setIsSideTabOpen(true);
  const handleCloseTab = () => setIsSideTabOpen(false);

  const { data } = useArtistList({
    name: debouncedSearchText,
    ...artistData,
  });

  const gender = [
    {
      id: 0,
      title: "여성",
      name: "FEMALE",
    },
    { id: 1, title: "남성", name: "MALE" },
  ];
  const job = [
    { id: 0, title: "배우", name: "ACTOR" },
    { id: 1, title: "모델", name: "MODEL" },
    { id: 2, title: "인플루언서", name: "INFLUENCER" },
    { id: 3, title: "뮤지컬배우", name: "MUSICAL_ACTOR" },
    { id: 4, title: "가수", name: "SINGER" },
  ];
  const keyword = [
    { id: 1, title: "동양적인", name: "" },
    { id: 2, title: "강아지상", name: "" },
    { id: 3, title: "날카로운", name: "" },
    { id: 4, title: "고양이상", name: "" },
    { id: 5, title: "토끼상", name: "" },
    { id: 6, title: "서구적인", name: "" },
    { id: 7, title: "부드러운", name: "" },
    { id: 8, title: "터프한", name: "" },
    { id: 9, title: "개성있는", name: "" },
    { id: 10, title: "청순한", name: "" },
    { id: 11, title: "섹시한", name: "" },
    { id: 12, title: "반항적인", name: "" },
    { id: 13, title: "중성적인", name: "" },
    { id: 14, title: "귀여운", name: "" },
  ];
  const hobby = [
    { id: 15, title: "액션", name: "" },
    { id: 16, title: "승마", name: "" },
    { id: 17, title: "영어", name: "" },
    { id: 18, title: "태권도", name: "" },
    { id: 19, title: "중국어", name: "" },
    { id: 20, title: "검도", name: "" },
    { id: 21, title: "달리기", name: "" },
    { id: 22, title: "일본어", name: "" },
    { id: 23, title: "한국무용", name: "" },
    { id: 24, title: "발레", name: "" },
    { id: 25, title: "수영", name: "" },
    { id: 26, title: "불어", name: "" },
  ];

  return (
    <ContentWrap className="py-4">
      <SideTab isOpen={isSideTabOpen} onClose={handleCloseTab}>
        <div className="pl-2 p-4 flex items-center justify-between">
          <div
            onClick={handleCloseTab}
            className="MBody18 cursor-pointer flex items-center"
          >
            <ArrowLeft />
            <div>필터</div>
          </div>
          <div className="BBody18 cursor-pointer text-Blue04">초기화</div>
        </div>
        <div className="pt-6 pl-4 pb-[100px] flex flex-col items-start gap-10 overflow-y-auto h-[480px]">
          <TitleWrap title="성별" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {gender.map((item, i) => {
                return (
                  <Chip
                    selected={artistData.gender === item.name}
                    onClick={() => {
                      if (artistData.gender === item.name) {
                        setArtistData({ ...artistData, gender: "" });
                      } else {
                        setArtistData({ ...artistData, gender: item.name });
                      }
                    }}
                    key={item.id}
                    title={item.title}
                  ></Chip>
                );
              })}
            </div>
          </TitleWrap>
          <TitleWrap title="직업" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {job.map((item, i) => {
                return (
                  <Chip
                    selected={artistData.job === item.name}
                    onClick={() => {
                      if (artistData.job === item.name) {
                        setArtistData({ ...artistData, job: "" });
                      } else {
                        setArtistData({ ...artistData, job: item.name });
                      }
                    }}
                    key={item.id}
                    title={item.title}
                  ></Chip>
                );
              })}
            </div>
          </TitleWrap>
          <TitleWrap title="키워드" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {keyword.map((item, i) => {
                return (
                  <Chip
                    onClick={() => {
                      setArtistData({
                        ...artistData,
                        hashtagIds: [...artistData.hashtagIds, item.id],
                      });
                    }}
                    key={item.id}
                    title={item.title}
                  ></Chip>
                );
              })}
            </div>
          </TitleWrap>
          <TitleWrap title="특기" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {hobby.map((item, i) => {
                return <Chip key={item.id} title={item.title}></Chip>;
              })}
            </div>
          </TitleWrap>
          <TitleWrap title="나이" subTitle="중복선택가능">
            <RangeInput fixedMinPrice={0} fixedMaxPrice={100} priceGap={50} />
          </TitleWrap>
          <TitleWrap title="몸무게" subTitle="중복선택가능">
            progrssBar
          </TitleWrap>
          <TitleWrap title="키" subTitle="중복선택가능">
            progrssBar
          </TitleWrap>
        </div>
        <div className="fixed pb-[19px] bottom-0 px-4">
          <Button
            className="w-[320px] border-none bg-Blue04 text-white"
            text={"필터 적용하기"}
          ></Button>
        </div>
      </SideTab>

      <div>
        <SearchBox
          className="w-full"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="아티스트 이름을 입력해 주세요"
        />
      </div>
      <div className="text-right my-4">
        <Chip onClick={handleOpenTab} title={"필터"}></Chip>
      </div>
      <div className="mb-20 grid grid-cols-2 gap-2">
        {data?.data.data.content?.map((item, idx) => {
          return (
            <ProfileInfoCard
              key={idx}
              onClick={() => {
                navigate(`${item.profileId}`);
              }}
              src={item?.thumbnailUrl}
              name={item.profileName}
              info={item.birthDate}
              size={`${item.height}cm ${item.weight}kg`}
            />
          );
        })}
      </div>
    </ContentWrap>
  );
};

export default ArtistList;
