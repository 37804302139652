import React, { useEffect, useState } from "react";

import ContentWrap from "components/ContentWrap";
import TitleWrap from "components/TitleWrap";
import { ReactComponent as IconPlus } from "assets/icon_plus.svg";
import Input from "components/Input";
import ImgUpload from "components/ImgUpload";
import requestToCastingVote from "utils/requestToCastingVote";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import Button from "components/Button";
import { useCreateStaffProfileMutation, useMyPage } from "api/staff";
import { useNavigate } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";

const CreateStaffProfile = () => {
  const navigate = useNavigate();
  const [staffData, setStaffData] = useState<{
    filmographies: {
      title: string;
      releaseYear: number;
      orderNumber: number;
    }[];
    attachments: {
      id: number;
      type: string;
      orderNumber: number;
      url?: string;
    }[];
  }>({
    filmographies: [
      {
        title: "",
        releaseYear: 0,
        orderNumber: 0,
      },
    ],
    attachments: [],
  });

  const { data, isLoading, isError, refetch } = useMyPage();

  const Staff = data?.data.data;

  useEffect(() => {
    if(data) {
      setStaffData({
        ...staffData,
        filmographies: Staff?.filmographies?.map(
          (film: {
            title: string;
            releaseYear: string;
            orderNumber: number;
          }) => ({
            title: film.title,
            releaseYear: Number(film.releaseYear),
            orderNumber: film.orderNumber,
          })
        ) || [],
        attachments: Staff?.attachments.map(
          (attachment: {
            id: number;
            type: {
              code: string;
              label: string;
            };
            url: string;
            orderNumber: number;
          }) => ({
            id: attachment.id,
            type: attachment.type.code,
            orderNumber: attachment.orderNumber,
            url: attachment.url,
          })
        ) || [],
    });
  }
  }, [data]);

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    const formData = new FormData();
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        formData.append("files", fileList[i]);
      }
      requestToCastingVote({
        method: "POST",
        url: "upload/image",
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then((res) => {
          const updateItem = res.data.data.map(
            (
              item: { attachmentId: number; attachmentUrl: string },
              idx: number
            ) => ({
              id: item.attachmentId,
              type: "THUMBNAIL",
              orderNumber: idx + 1,
              url: item.attachmentUrl,
            })
          );

          setStaffData({
            ...staffData,
            attachments: [...staffData.attachments, ...updateItem],
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleAddFilmography = () => {
    setStaffData((prevState) => ({
      ...prevState,
      filmographies: [
        ...prevState.filmographies,
        {
          title: "",
          releaseYear: 0,
          orderNumber: prevState.filmographies.length,
        },
      ],
    }));
  };

  const handleDeleteFilmography = (index: number) => {
    setStaffData((prevState) => ({
      ...prevState,
      filmographies: prevState.filmographies.filter((_, i) => i !== index),
    }));
  };

  const handleInputChange = (
    index: number,
    field: "title" | "releaseYear",
    value: string | number
  ) => {
    setStaffData((prevState) => ({
      ...prevState,
      filmographies: prevState.filmographies.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      ),
    }));
  };

  const { mutate, isSuccess } = useCreateStaffProfileMutation(staffData);

  return (
    <div>
      <HeaderWithBackButton title="내 프로필" />
      <ContentWrap className="py-20">
        <div className="flex items-start gap-5 mt-10 flex-col">
          <div className="w-[285px]">
            <TitleWrap
              subTitle="4:5 / 10MB 이하"
              title={"프로필 사진 업로드"}
              require
            >
              {staffData?.attachments.length ? (
                <div className="relative w-[172px]">
                  <img
                    className="w-[172px] h-[216px] rounded-[10px] object-cover"
                    src={`https://test.castingvote.tv/uploads${staffData?.attachments[0].url}`}
                    alt="THUMBNAIL"
                  />
                  <XButton
                    onClick={() => {
                      setStaffData({ ...staffData, attachments: [] });
                    }}
                    className="absolute cursor-pointer top-4 right-4"
                  />
                </div>
              ) : (
                <ImgUpload
                  onChange={(e) => {
                    imgUpload(e);
                  }}
                  id={"profileImg"}
                >
                  <div className="w-[172px] cursor-pointer h-[216px] MBody16 flex flex-col justify-center text-center text-Blue04 border border-[#D9D9D9] border-dashed">
                    <div>사진 업로드</div>
                    <IconPlus className="mx-auto mt-4" />
                  </div>
                </ImgUpload>
              )}
              <div className="mt-4 rounded-[10px] bg-Gray01 p-4">
                <div className="RBody14 text-Gray05">
                  <div>이런 사진은 프로필 등록이 불가능해요.</div>
                  <ul>
                    <li>• 워터마크가 포함된 사진</li>
                    <li>• 과도하게 편집된 사진</li>
                    <li>• 화질이 너무 안 좋은 사진</li>
                    <li>• 다른 사람이 포함된 사진</li>
                    <li>• 담배나 술이 확인되는 사진</li>
                    <li>• 사회통념에 반하는 내용이 들어간 사진</li>
                    <li>
                      • 타인이나 특정 계층을 모욕하거나 비하•비방하는 내용의
                      사진
                    </li>
                  </ul>
                </div>
              </div>
            </TitleWrap>
          </div>
          <div>
            <TitleWrap require title={"필모그래피"}>
              <IconPlus
                className="absolute BBody16 text-Blue04 cursor-pointer -top-11 right-0"
                onClick={() => handleAddFilmography()}
              />
              <div className="flex flex-col gap-2">
                {staffData.filmographies.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="w-full rounded-[10px] relative gap-4 bg-Gray01 p-4 flex"
                    >
                      <TitleWrap title={"제작연도"} require>
                        <Input
                          type={"text"}
                          className="w-[93px]"
                          placeholder="제작연도를 입력해 주세요"
                          value={String(item.releaseYear)}
                          onChange={(e) =>
                            handleInputChange(
                              i,
                              "releaseYear",
                              Number(e.target.value.replace(/[^0-9]/g, ""))
                            )
                          }
                        />
                      </TitleWrap>
                      <TitleWrap subTitle="최대36자" title={"작품제목"} require>
                        <Input
                          type={"text"}
                          className="w-full"
                          placeholder="작품제목을 입력해 주세요"
                          value={item.title}
                          onChange={(e) =>
                            handleInputChange(i, "title", e.target.value)
                          }
                        />
                      </TitleWrap>
                      {i > 0 && (
                        <div
                          onClick={() => handleDeleteFilmography(i)}
                          className="absolute right-5 BBody16 text-Blue04 cursor-pointer"
                        >
                          삭제
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </TitleWrap>
          </div>
        </div>
        <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 border-t border-Gray03 mt-5 py-4 px-5">
          <Button
            onClick={() => {
              mutate(staffData, {
                onSuccess: () => {
                  navigate("/staff/my");
                },
              });
            }}
            className="w-full h-12 bg-Blue04 text-white border-none"
            text={"등록하기"}
          ></Button>
        </div>
      </ContentWrap>
    </div>
  );
};

export default CreateStaffProfile;
