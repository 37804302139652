import React from "react";

import { ReactComponent as Search } from "../assets/search.svg";

interface SearchBoxType {
  placeholder: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBox = ({ placeholder, className, onChange }: SearchBoxType) => {
  return (
    <div className="relative MBody14">
      <input
        onChange={onChange}
        placeholder={placeholder}
        className={`border rounded-full py-3 pl-4 pr-16 border-[#D9D9D9] ${className}`}
      />
      <Search className="cursor-pointer absolute right-4 top-2.5 w-7 h-7 " />
    </div>
  );
};

export default SearchBox;
