import React from "react";
import Button from "./Button";

import Male from "assets/male.png";
import Female from "assets/female.png";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";

interface StaffCardType {
  onClick?: () => void;
  image?: string;
  name?: string;
  gender?: string;
  role?: string;
  agency?: string;
}

const StaffCard = ({
  onClick,
  image,
  name,
  gender,
  role,
  agency,
}: StaffCardType) => {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer border border-[#E0E3EB] gap-4 rounded-[10px] p-4 w-full flex items-start"
    >
      <img
        src={
          image === null || gender === "MALE"
            ? Male
            : image === null || gender === "FEMALE"
            ? Female
            : `https://test.castingvote.tv/uploads${image}`
        }
        alt="Thumnail"
        className="w-20 h-20 rounded-full"
      />
      <div className="flex-1">
        <div className="flex items-center gap-2 BBody16">
          <div>{name}</div>
          <Bookmark />
        </div>
        <div className="flex items-center text-[#898A8D] gap-2 my-2 BBody14">
          <div>{role}</div>
          <div>|</div>
          <div>{agency}</div>
        </div>
        <div className="MBody14 text-Gray05">
          필모그래피, 필모그래피, 필모그래피
        </div>
        <Button
          // stopPropagation
          className="px-2 !py-1.5 mt-2 bg-Blue01 !border-none MBody14 text-Blue04 w-full"
          text={"프로필 보내기"}
        ></Button>{" "}
      </div>
    </div>
  );
};

export default StaffCard;
