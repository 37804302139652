import React from 'react';

interface ContentWrapType {
    className ?:string;
    children?: React.ReactNode
}

const ContentWrap = ({children, className}:ContentWrapType) => {
    return (
        <div className={`${className} max-w-[400px] min-w-[320px] `}>
            {children}
        </div>
    );
};
// desktop:max-w-[1280px]

export default ContentWrap;