import React, { useEffect } from "react";
import ContentWrap from "components/ContentWrap";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import { ReactComponent as Share } from "../assets/share.svg";

import Insta from "../assets/Instagram_black.png";
import Youtue from "../assets/YouTube_black.png";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { info } from "store/auth/atom";
import { useArtistDetail } from "api/artist";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import YouTube from "react-youtube";
import Button from "components/Button";

const ArtistDetail = () => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(info);

  const { artistId } = useParams();

  const { data, isLoading, isError, refetch } = useArtistDetail(
    Number(artistId) as number
  );

  const ArtistData = data?.data.data;

  const calculateAge = () => {
    const currentYear = new Date().getFullYear();
    const calculatedAge =
      currentYear - Number(ArtistData?.birthDate.split("-")[0]);
    return calculatedAge;
  };

  const handleOpenNewTab = (url: string) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  const imageType = (type: string) => {
    return ArtistData?.attachments?.filter(
      (image) => image.type.code === type
    )[0];
  };

  const opt = {
    height: "171",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const groupedByYear = ArtistData?.filmographies?.reduce((acc: any, item) => {
    const year = item.releaseYear;
    if (!acc[year]) {
      acc[year] = [];
    }

    acc[year].push(item);

    acc[year].sort((a: any, b: any) => a.orderNumber - b.orderNumber);

    return acc;
  }, {});

  return (
    <>
      {isLoading || isError ? (
        <div>loading</div>
      ) : (
        <ContentWrap className="flex pb-20 flex-col items-start">
          <div className="w-full flex flex-col gap-4">
            <div className="mt-4">
              <div className="BBody14 text-[#898A8D] mt-2">
                {ArtistData?.basicInfo.job.label}
              </div>
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <div className="BBody20">{ArtistData?.profileName}</div>
                  {ArtistData?.snsInfo.instagram && (
                    <img
                      onClick={() => {
                        handleOpenNewTab(ArtistData?.snsInfo.instagram!);
                      }}
                      className="cursor-pointer"
                      src={Insta}
                      alt="instagram"
                    />
                  )}
                  {ArtistData?.snsInfo.youtube && (
                    <img
                      onClick={() => {
                        handleOpenNewTab(ArtistData?.snsInfo.youtube!);
                      }}
                      className="cursor-pointer"
                      src={Youtue}
                      alt="youtube"
                    />
                  )}
                </div>
                <div className="flex items-center gap-2">
                  <Bookmark className="cursor-pointer" />
                  <Share className="cursor-pointer" />
                </div>
              </div>
              <div className="flex my-2 items-center RBody16 gap-2">
                <div>{calculateAge()}살</div>
                <div>{`${ArtistData?.birthDate.split("-")[0]}년생`}</div>
              </div>
              <div className="flex flex-wrap gap-x-2 RBody20 text-Blue04">
                {ArtistData?.hashtags?.map((item) => {
                  return <div key={item.id}>#{item.name}</div>;
                })}
              </div>
            </div>
            <div>
              <img
                src={`https://test.castingvote.tv/uploads/${ArtistData?.thumbnailUrl}`}
                alt=""
                className="w-full h-[449x] object-cover rounded-[10px]"
              />
              <div className="flex items-center justify-between mt-2">
                <Swiper
                  spaceBetween={8}
                  slidesPerView={
                    ArtistData?.attachments?.length! - 1 > 2
                      ? 2
                      : ArtistData?.attachments?.length! - 1
                  }
                  loop
                >
                  {ArtistData?.attachments?.map((item, idx) => {
                    if (item.type.code === "ADDITIONAL_IMAGE") {
                      return (
                        <SwiperSlide key={idx}>
                          <img
                            src={`https://test.castingvote.tv/uploads/${item.url}`}
                            alt="ADDITIONAL_IMAGE"
                            className="w-[176px] h-[176px] rounded-[10px]"
                          />
                        </SwiperSlide>
                      );
                    }
                  })}
                  {imageType("FULL_BODY")?.url !== undefined && (
                    <SwiperSlide>
                      <img
                        src={`https://test.castingvote.tv/uploads/${
                          imageType("FULL_BODY")?.url
                        }`}
                        alt="FULL_BODY"
                        className="w-[176px] h-[176px] rounded-[10px]"
                      />
                    </SwiperSlide>
                  )}
                  {imageType("FRONT_HALF_BODY")?.url !== undefined && (
                    <SwiperSlide>
                      <img
                        src={`https://test.castingvote.tv/uploads/${
                          imageType("FRONT_HALF_BODY")?.url
                        }`}
                        alt="FRONT_HALF_BODY"
                        className="w-[176px] h-[176px] rounded-[10px]"
                      />
                    </SwiperSlide>
                  )}
                  {imageType("LEFT_HALF_BODY")?.url !== undefined && (
                    <SwiperSlide>
                      <img
                        src={`https://test.castingvote.tv/uploads/${
                          imageType("LEFT_HALF_BODY")?.url
                        }`}
                        alt="LEFT_HALF_BODY"
                        className="w-[176px] h-[176px] rounded-[10px]"
                      />
                    </SwiperSlide>
                  )}
                  {imageType("RIGHT_HALF_BODY")?.url !== undefined && (
                    <SwiperSlide>
                      <img
                        src={`https://test.castingvote.tv/uploads/${
                          imageType("RIGHT_HALF_BODY")?.url
                        }`}
                        alt="RIGHT_HALF_BODY"
                        className="w-[176px] h-[176px] rounded-[10px]"
                      />
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {ArtistData?.videoLinks?.map((item, idx) => {
                let link = "";

                if (item.link.startsWith("https://www.youtube.com")) {
                  link = item.link.split("watch?v=")[1];
                } else {
                  link = item.link.split("?")[0].split("/")[3];
                }
                return (
                  <YouTube
                    key={item.orderNumber}
                    className={"w-full"}
                    opts={opt}
                    iframeClassName="youtubeRounded"
                    videoId={link}
                  />
                );
              })}
            </div>
          </div>
          <div className="flex-1 w-full">
            <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
            <div className="flex flex-col gap-10">
              <div className="flex items-start">
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody14 text-[#898A8D]">키</div>
                  <div className="MBody16">
                    {ArtistData?.basicInfo.height
                      ? ArtistData?.basicInfo.height
                      : "-"}
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody14 text-[#898A8D]">몸무게</div>
                  <div className="MBody16">
                    {ArtistData?.basicInfo.weight
                      ? ArtistData?.basicInfo.weight
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="flex items-start">
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody14 text-[#898A8D]">신발사이즈</div>
                  <div className="MBody16">
                    {ArtistData?.basicInfo.shoeSize
                      ? ArtistData?.basicInfo.shoeSize
                      : "-"}
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody14 text-[#898A8D]">3size</div>
                  <div className="MBody16">
                    {`${ArtistData?.basicInfo.threeSize.bust}-${ArtistData?.basicInfo.threeSize.waist}-${ArtistData?.basicInfo.threeSize.hip}`}
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col gap-2">
                <div className="RBody14 text-[#898A8D]">특기</div>
                <div className="MBody16 flex items-center">
                  {ArtistData?.hobbies.map((item, idx) => {
                    return (
                      <div key={item.id}>
                        {item.name}
                        {idx < ArtistData.hobbies.length - 1 ? "/" : ""}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="w-full flex flex-col gap-2">
                <div className="RBody14 text-[#898A8D]">거주지</div>
                <div className="MBody16">
                  {ArtistData?.basicInfo.address.sido}
                </div>
              </div>
              <div className="w-full flex flex-col gap-2">
                <div className="RBody14 text-[#898A8D]">학력</div>
                <div className="MBody16">
                  {ArtistData?.basicInfo.education
                    ? ArtistData?.basicInfo.education
                    : "-"}
                </div>
              </div>
              <div className="flex items-start">
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody14 text-[#898A8D]">매니저/소속사</div>
                  <div className="MBody16">
                    {ArtistData?.basicInfo.agency
                      ? ArtistData?.basicInfo.agency
                      : "-"}
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody14 text-[#898A8D]">출연료</div>
                  <div className="MBody16">
                    {ArtistData?.salary
                      ? ArtistData?.salary.toLocaleString("ko-KR")
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
            <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
            <div className="w-full flex flex-col gap-2">
              <div className="RBody16 text-[#898A8D]">자기소개</div>
              <div className="MBody16 max-h-[336px] whitespace-pre-wrap overflow-y-scroll">
                {ArtistData?.introduce}
              </div>
            </div>
            <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
            <div className="w-full flex flex-col gap-2">
              <div className="RBody16 text-[#898A8D]">필모그래피</div>
              {ArtistData && (
                <div className="flex flex-col gap-4">
                  {Object.keys(groupedByYear)?.map((year) => (
                    <div className="flex items-start gap-4" key={year}>
                      <div className="RBody14 text-Gray09">{year}</div>
                      <div className="flex flex-col gap-4">
                        {groupedByYear[year].map((item: any) => (
                          <div key={item.id}>
                            <div className="RBody14 text-Gray05">
                              {item?.type?.label}
                            </div>
                            <div className="mt-2 BBody16 flex  items-center gap-2">
                              <div>{item.title}</div>
                              <div className="text-Gray05">
                                {item?.role?.label}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 border-t border-Gray03 mt-5 py-4 px-5">
            {" "}
            <Button
              text={"제안하기"}
              className="w-full text-Gray00 bg-Blue04"
            ></Button>
          </div>
        </ContentWrap>
      )}
    </>
  );
};

export default ArtistDetail;
