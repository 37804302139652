import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "assets/arrowDown.svg";

interface DropdownProps {
  children?: React.ReactNode;
  className?: string;
  width?: string;
  innerText?: string;
  error?: boolean;
  disabled?: boolean;
}

const Dropdown = ({
  children,
  innerText,
  className,
  width,
  error,
  disabled,
}: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showOption, setShowOption] = useState(false);
  

  useEffect(() => {
    const handleOutsideClose = (e: { target: any }) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target))
        setShowOption(false);
    };
    document.addEventListener("click", handleOutsideClose);

    return () => document.removeEventListener("click", handleOutsideClose);
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      onClick={() => {
        if (!disabled) {
          setShowOption((showOption) => !showOption);
        }
      }}
      className={`dropdown cursor-pointer box-border h-12 relative flex items-center justify-between px-4 py-3 border rounded-[10px]
        // ${error ? "border-red" : "border-Gray-40"}
        // ${disabled ? "border-none bg-Gray-20" : "bg-Gray-10"}
        ${className} ${width}`}
    >
      <div
        className={`${
          innerText === "선택" ? "text-[#898A8D]" : "text-Gray09"
        }`}
      >
        {innerText}
      </div>
      <ArrowDown
        className={`transition-all ${showOption ? "rotate-180" : ""}`}
      />
      {showOption && (
        <div
          className={`first:px-4 first:py-3 absolute z-[101] list-none top-[47px] left-0 max-h-[290px] rounded-lg overflow-y-auto border border-Gray-40 bg-Gray-10 ${width}`}
        >
          <div className="p-2 bg-white">{children}</div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
