import React from "react";
import ContentWrap from "components/ContentWrap";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import { ReactComponent as Share } from "../assets/share.svg";

import Male from "assets/male.png";
import Female from "assets/female.png";

import Button from "components/Button";
import Chip from "components/Chip";
import StaffWorkCard from "components/StaffWorkCard";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { info } from "store/auth/atom";
import { useStaffDetail } from "api/staff";

const StaffDetail = () => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(info);

  const { staffId } = useParams();

  const { data } = useStaffDetail(Number(staffId));

  const StaffData = data?.data.data;

  const sortedFilmo = StaffData?.filmographies.sort(
    (a, b) => Number(b.releaseYear) - Number(a.releaseYear)
  );

  return (
    <ContentWrap className="pb-20 flex flex-col">
      <div>
        <div className="w-full mt-4 items-start flex gap-7">
          <img
            src={
              !StaffData ||
              (StaffData?.thumbnailUrl === null &&
                StaffData?.gender.code === "MALE")
                ? Male
                : !StaffData ||
                  (StaffData?.thumbnailUrl === null &&
                    StaffData?.gender.code === "FEMALE")
                ? Female
                : `https://test.castingvote.tv/uploads${StaffData?.thumbnailUrl}`
            }
            alt="Thumnai"
            className="w-[160px] h-[196px] rounded-[10px]"
          />
          <div>
            <div className="flex items-center gap-2 BBody20 text-Gray09">
              <div>
                {StaffData?.profileName ? StaffData?.profileName : "홍길동"}
              </div>
              <div className="flex items-center">
                <Bookmark />
                <Share />
              </div>
            </div>
            <div className="my-2 MCaption12 flex items-center gap-2 text-[#898A8D]">
              <div>{StaffData?.role.label ? StaffData?.role.label : "Job"}</div>
              <div>•</div>
              <div className=" overflow-hidden text-ellipsis break-words max-w-[91px]">
                {StaffData?.agency}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {sortedFilmo?.map((item, i) => {
                return (
                  <div key={i} className="flex items-center gap-2">
                    <div className="RBody14 text-[#898A8D]">
                      {item?.releaseYear}
                    </div>
                    <div className="MBody14 text-Gray09">{item?.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Button
          className="w-full bg-Blue04 py-3 text-white !border-none mt-4"
          text={"프로필 보내기"}
        ></Button>
      </div>
      <div className="w-full my-4">
        <div>
          <Chip title={"전체"}></Chip>
        </div>
        <div className=" gap-x-4 gap-y-2 pt-4 grid grid-cols-2">
          <StaffWorkCard onClick={() => navigate("/staff/filmo/3")} />
          <StaffWorkCard />
          <StaffWorkCard />
          <StaffWorkCard />
        </div>
      </div>
    </ContentWrap>
  );
};

export default StaffDetail;
