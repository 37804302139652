import React from "react";

import test1 from "../assets/longTestImg.png";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";

interface WorkCardType {
  className?: string;
  onClick?: () => void;
  format?: string;
  src?: string;
  title?: string;
  manager?: string;
  company?: string;
}

const WorkCard = ({
  className,
  onClick,
  format,
  title,
  src,
  manager,
  company,
}: WorkCardType) => {
  return (
    <div
      onClick={onClick}
      className="border cursor-pointer h-full border-[#D9D9D9] rounded-[10px] overflow-hidden"
    >
      {src && (
        <img
          src={`https://test.castingvote.tv/uploads${src}`}
          className="w-full object-cover h-[117px]"
          alt=""
        />
      )}
      <div className="p-4 h-full">
        <div className="flex items-center text-[#898A8D] MBody14 justify-between">
          <div>{format}</div>
          <Bookmark />
        </div>
        <div className="BBody18 mt-4 mb-2">{title}</div>
        <div className="flex items-center text-[#898A8D] gap-2 MBody14">
          <div>{manager}</div>
          <div>|</div>
          <div>{company}</div>
        </div>
      </div>
    </div>
  );
};

export default WorkCard;
