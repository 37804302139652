import React, { useState } from "react";

import NoProfile from "../assets/noProfile.png";

import ContentWrap from "components/ContentWrap";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useProductionsQuery } from "api/staff";
import { ReactComponent as Kebab } from "assets/kebabIcon.svg";
import OptionSelectBox from "components/OptionSelectBox";

const StaffMyCasting = () => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState<number | null>(null);

  const { data } = useProductionsQuery();

  const menuList = [
    { data: "수정", loca: `/staff/post` },
    { data: "삭제" },
    { data: "작품보기", path: `/staff/filmo` },
  ];

  return (
    <div>
      <HeaderWithBackButton title="내 캐스팅">
        <div
          onClick={() => {
            navigate("/staff/post");
          }}
          className="cursor-pointer BBody16 text-Blue04"
        >
          등록
        </div>
      </HeaderWithBackButton>
      {data?.data.data === null ? (
        <ContentWrap className="text-center mx-auto flex-col my-28">
          <img
            className="w-[60px] h-[60px] mx-auto"
            src={NoProfile}
            alt="NoProfile"
          />
          <div className="mt-4 mb-10 MBody16 text-Gray05 whitespace-normal">
            아직 캐스팅 중인 작품이 없어요.
            <br />
            작품과 캐스팅 공고를 등록하면 캐스팅보트의
            <br />
            아티스트가 지원할 수 있어요!
          </div>
          <Button
            className="px-4 py-3 text-Gray00 bg-Blue04 border-none"
            text={"캐스팅 등록하기"}
            onClick={() => {
              navigate("/staff/post");
            }}
          />
        </ContentWrap>
      ) : (
        <ContentWrap className="py-20">
          <div className="MBody16 text-[#898A8D] mb-4">작품</div>
          <div className="flex flex-col gap-4">
            {data?.data.data.content.map((item, i) => {
              return (
                <div  onClick={() => {
                  navigate(`/staff/casting-call/my?id=${item?.productionId}`);
                }}  key={i} className="flex flex-col gap-2 RBody14 cursor-pointer">
                  <div className="text-[#898A8D]">{item.format.label}</div>
                  <div className="flex items-center justify-between">
                    <div className="flex relative w-full items-center gap-2">
                      <div
                        className="RBody16 cursor-pointer text-Gray09"
                      >
                        {item.title}
                      </div>
                      <div className="text-[#898A8D]">{item.companyName}</div>
                      <div onClick={(e) => {
                            e.stopPropagation();
                            setMenu((prev) => (prev === i ? null : i));
                          }} className="absolute right-0">
                        <Kebab
                          className="cursor-pointer"
                        />
                      </div>
                      {menu === i && (
                        <OptionSelectBox onClose={() => setMenu(null)}>
                          {menuList.map((select, i) => [
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                if (select.path) {
                                  navigate(
                                    `${select?.path}/${item.productionId}`
                                  );
                                } else {
                                  navigate(
                                    `${select?.loca}?id=${item.productionId}`
                                  );
                                }
                              }}
                              key={i}
                            >
                              {select.data}
                            </div>,
                          ])}
                        </OptionSelectBox>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </ContentWrap>
      )}
    </div>
  );
};

export default StaffMyCasting;
