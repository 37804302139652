import React, { useEffect, useState } from "react";
import Chip from "components/Chip";
import ContentWrap from "components/ContentWrap";
import Input from "components/Input";
import TitleWrap from "components/TitleWrap";
import { ReactComponent as ProfileInsta } from "assets/profileInsta.svg";
import { ReactComponent as ProfileYoutube } from "assets/profileYoutube.svg";
import { ReactComponent as Tooltip } from "assets/tooltip.svg";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Textarea from "components/Textarea";
import Dropdown from "components/Dropdown";

import { ReactComponent as IconPlus } from "assets/icon_plus.svg";
import { ReactComponent as Delete } from "assets/delete.svg";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import ImgUpload from "components/ImgUpload";
import requestToCastingVote from "utils/requestToCastingVote";
import {
  useCreateArtistProfileMutation,
  useUpdateArtistProfileMutation,
} from "api/artist";
import YouTube from "react-youtube";
import { useMyPage } from "api/staff";
import { useNavigate } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";

const CreateArtistProfile = () => {
  const navigate = useNavigate();
  const [hobbyItem, setHobbyItem] = useState("");
  const [size, setSize] = useState("");
  const [keywordItem, setKeywordItem] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [artistProfile, setArtistProfile] = useState<{
    profileId: number;
    name: string;
    gender: string;
    job: string;
    zonecode: string;
    roadAddress: string;
    jibunAddress: string;
    sido: string;
    sigungu: string;
    birthDate: string;
    height: number;
    weight: number;
    shoeSize: number;
    bust: number;
    waist: number;
    hip: number;
    education: string;
    rank: string;
    salary: number;
    agency: string;
    introduce: string;
    instagram: string;
    youtube: string;
    facebook: string;
    twitter: string;
    tiktok: string;
    linkedin: string;
    hashtags: string[];
    hobbies: string[];
    filmographies: {
      title: string;
      type: string;
      role: string;
      releaseYear: number;
      description: string;
      orderNumber: number;
    }[];
    attachments: {
      id: number;
      type: string;
      orderNumber: number;
      url?: string;
    }[];
    videoLinks: {
      link: string;
      type: string;
      orderNumber: number;
    }[];
  }>({
    profileId: 0,
    name: "",
    gender: "FEMALE",
    job: "",
    zonecode: "",
    roadAddress: "",
    jibunAddress: "",
    sido: "",
    sigungu: "",
    birthDate: "",
    height: 0,
    weight: 0,
    shoeSize: 0,
    bust: 0,
    waist: 0,
    hip: 0,
    education: "",
    rank: "RANK_8",
    salary: 0,
    agency: "",
    introduce: "",
    instagram: "",
    youtube: "",
    facebook: "",
    twitter: "",
    tiktok: "",
    linkedin: "",
    hashtags: [],
    hobbies: [],
    filmographies: [
      {
        title: "",
        type: "",
        role: "",
        releaseYear: 0,
        description: "",
        orderNumber: 0,
      },
    ],
    attachments: [],
    videoLinks: [],
  });

  const addFilmographyItem = () => {
    setArtistProfile((prevState) => ({
      ...prevState,
      filmographies: [
        ...prevState.filmographies,
        {
          title: "",
          type: "",
          role: "",
          releaseYear: 0,
          description: "",
          orderNumber: prevState.filmographies.length,
        },
      ],
    }));
  };

  const removeFilmographyItem = (idxToRemove: number) => {
    setArtistProfile((pre) => ({
      ...pre,
      filmographies: pre.filmographies.filter((_, i) => i !== idxToRemove),
    }));
  };

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const fileList = e.target.files;
    const IMAGE_SIZE_LIMIT = 1024 ** 2 * 10; // 10MB
    const formData = new FormData();
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        console.log(fileList[i].size > IMAGE_SIZE_LIMIT ? `넘음: ${fileList[i]}` : `안넘음: ${fileList[i]}`)
        formData.append("files", fileList[i]);
      }
      requestToCastingVote({
        method: "POST",
        url: "upload/image",
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then((res) => {
          const updateItem = res.data.data.map(
            (
              item: { attachmentId: number; attachmentUrl: string },
              idx: number
            ) => ({
              id: item.attachmentId,
              type: type,
              orderNumber: idx + 1,
              url: item.attachmentUrl,
            })
          );

          setArtistProfile({
            ...artistProfile,
            attachments: [...artistProfile.attachments, ...updateItem],
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  console.log(artistProfile);

  const opt = {
    height: "171",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const jopList = [
    { id: 0, title: "배우", name: "ACTOR" },
    { id: 1, title: "모델", name: "MODEL" },
    { id: 2, title: "인플루언서", name: "INFLUENCER" },
    { id: 3, title: "뮤지컬배우", name: "MUSICAL_ACTOR" },
    { id: 4, title: "가수", name: "SINGER" },
  ];

  const typeList = [
    { id: 0, title: "영화", name: "MOVIE" },
    { id: 1, title: "드라마", name: "TV_SERIES" },
    { id: 2, title: "예능", name: "" },
    { id: 3, title: "광고", name: "" },
    { id: 4, title: "뮤직비디오", name: "MUSIC_VIDEO" },
    { id: 5, title: "웹드라마", name: "WEB_SERIES" },
    { id: 6, title: "숏폼", name: "SHORT_FILM" },
    { id: 7, title: "독립영화", name: "" },
  ];
  const roleList = [
    { id: 0, title: "주연", name: "LEADING_ACTOR" },
    { id: 1, title: "조연", name: "SUPPORTING_ACTOR" },
    { id: 2, title: "조단역", name: "" },
    { id: 3, title: "단역", name: "BIT_PART" },
    { id: 4, title: "이미지단역", name: "EXTRA" },
    { id: 5, title: "보조출연", name: "CAMEO" },
  ];

  const keyword = [
    "동양적인",
    "강아지상",
    "날카로운",
    "고양이상",
    "토끼상",
    "서구적인",
    "부드러운",
    "터프한",
    "개성있는",
    "청순한",
    "섹시한",
    "반항적인",
    "중성적인",
    "귀여운",
  ];

  useEffect(() => {
    setSize(`${artistProfile.bust}-${artistProfile.waist}-${artistProfile.hip}`)
    const threeSize = size.split("-");
    const bust = Number(threeSize[0]);
    const waist = Number(threeSize[1]);
    const hip = Number(threeSize[2]);
    setArtistProfile({ ...artistProfile, bust: bust, waist: waist, hip: hip });
  }, []);

  const updataProfile = {
    profileId: artistProfile?.profileId,
    name: artistProfile.name,
    gender: artistProfile.gender,
    job: artistProfile.job,
    zonecode: "",
    roadAddress: "",
    jibunAddress: "",
    sido: artistProfile?.sido,
    sigungu: "",
    birthDate: artistProfile.birthDate,
    height: artistProfile.height,
    weight: artistProfile.weight,
    shoeSize: artistProfile.shoeSize,
    bust: artistProfile.bust,
    waist: artistProfile.waist,
    hip: artistProfile.hip,
    education: artistProfile.education,
    rank: artistProfile.rank,
    salary: artistProfile.salary,
    agency: artistProfile.agency,
    introduce: artistProfile.introduce,
    instagram: artistProfile.instagram,
    youtube: artistProfile.youtube,
    facebook: artistProfile.facebook,
    twitter: artistProfile.twitter,
    tiktok: artistProfile.tiktok,
    linkedin: artistProfile.linkedin,
    addHashtags: [],
    removeHashtagIds: [],
    addHobbies: [],
    removeHobbyIds: [],
    addFilmographies: [],
    removeFilmographyIds: [],
    addAttachments: [],
    removeAttachments: [],
    addVideoLinks: [],
    removeVideoLinks: [],
  };

  const { mutate, isSuccess } = useCreateArtistProfileMutation(artistProfile);
  const { mutate: updateMutate, isSuccess: updateSuccess } =
    useUpdateArtistProfileMutation(updataProfile);

  const { data, isLoading, isError, refetch } = useMyPage();

  const ArtistData = data?.data.data;

  useEffect(() => {
    if (ArtistData) {
      setArtistProfile({
        profileId: ArtistData.profileId,
        name: ArtistData.profileName,
        gender: "MALE",
        job: ArtistData.basicInfo.job.code,
        zonecode: ArtistData.basicInfo.address?.zonecode,
        roadAddress: ArtistData.basicInfo.address?.roadAddress,
        jibunAddress: ArtistData.basicInfo.address?.jibunAddress,
        sido: ArtistData.basicInfo.address?.sido,
        sigungu: ArtistData.basicInfo.address?.sigungu,
        birthDate: ArtistData.birthDate,
        height: ArtistData.basicInfo.height,
        weight: ArtistData.basicInfo.weight,
        shoeSize: ArtistData.basicInfo.shoeSize,
        bust: ArtistData.basicInfo.threeSize.bust,
        waist: ArtistData.basicInfo.threeSize.waist,
        hip: ArtistData.basicInfo.threeSize.hip,
        education: ArtistData.basicInfo.education,
        rank: ArtistData.rank.code,
        salary: ArtistData.salary,
        agency: ArtistData.basicInfo.agency,
        introduce: ArtistData.introduce,
        instagram: ArtistData.snsInfo.instagram,
        youtube: ArtistData.snsInfo.youtube,
        facebook: ArtistData.snsInfo.facebook,
        twitter: ArtistData.snsInfo.twitter,
        tiktok: ArtistData.snsInfo.tiktok,
        linkedin: ArtistData.snsInfo.linkedin,
        hashtags: ArtistData.hashtags.map(
          (tag: { id: number; name: string }) => tag.name
        ),
        hobbies: ArtistData.hobbies.map(
          (hobby: { id: number; name: string }) => hobby.name
        ),
        filmographies: ArtistData.filmographies.map(
          (film: {
            id: number;
            title: string;
            type: {
              code: string;
              label: string;
            };
            role: {
              code: string;
              label: string;
            };
            releaseYear: string;
            description: string;
            orderNumber: number;
          }) => ({
            title: film.title,
            type: film?.type?.code,
            role: film?.role?.code,
            releaseYear: Number(film.releaseYear),
            description: film.description,
            orderNumber: film.orderNumber,
          })
        ),
        attachments: ArtistData.attachments.map(
          (attachment: {
            id: number;
            type: {
              code: string;
              label: string;
            };
            url: string;
            orderNumber: number;
          }) => ({
            id: attachment.id,
            type: attachment.type.code,
            orderNumber: attachment.orderNumber,
            url: attachment.url,
          })
        ),
        videoLinks: ArtistData.videoLinks.map(
          (video: {
            id: number;
            link: string;
            type: {
              code: string;
              label: string;
            };
            orderNumber: number;
          }) => ({
            link: video.link,
            type: video.type.label,
            orderNumber: video.orderNumber,
          })
        ),
      });
    }
  }, [ArtistData]);

  const addVideoLink = (newLink: string, type: string) => {
    if (
      newLink.startsWith("https://www.youtube.com") ||
      newLink.startsWith("https://youtu.be/")
    ) {
      setArtistProfile((prevProfile) => {
        const newOrderNumber = prevProfile.videoLinks.length;

        return {
          ...prevProfile,
          videoLinks: [
            ...prevProfile.videoLinks,
            {
              link: newLink,
              type: type,
              orderNumber: newOrderNumber,
            },
          ],
        };
      });
    } else {
      alert("잘못된 유튜브 링크입니다.");
    }
  };

  const handleDeleteImage = (id: number) => {
    // id에 해당하는 이미지를 삭제
    const updatedAttachments = artistProfile.attachments.filter(
      (item) => item.id !== id
    );
    setArtistProfile({ ...artistProfile, attachments: updatedAttachments });
  };

  const handleDeleteVideo = (link: string) => {
    // id에 해당하는 이미지를 삭제
    const updatedVideos = artistProfile.videoLinks.filter(
      (item) => item.link !== link
    );
    setArtistProfile({ ...artistProfile, videoLinks: updatedVideos });
  };

  return (
    <div>
      <ContentWrap className="pb-20">
        <HeaderWithBackButton
          title={`프로필 ${ArtistData === null ? "등록" : "수정"}`}
        />
        <div className="flex items-start gap-5 mt-20 flex-col">
          <div className="flex-1 flex flex-col gap-10">
            <TitleWrap
              title={"대표 프로필 사진 업로드"}
              subTitle="4:5 / 10MB 이하"
              require
            >
              {artistProfile?.attachments.some(
                (item) => item.type === "THUMBNAIL"
              ) ? (
                artistProfile?.attachments
                  .filter((item) => item.type === "THUMBNAIL")
                  .map((item, idx) => (
                    <div key={item.id} className="relative">
                      <img
                        className="w-full h-[418px] object-cover"
                        src={`https://test.castingvote.tv/uploads${item.url}`}
                        alt="THUMBNAIL"
                      />
                      <XButton
                        onClick={() => {
                          handleDeleteImage(item.id);
                        }}
                        className="absolute cursor-pointer top-4 right-4"
                      />
                    </div>
                  ))
              ) : (
                <ImgUpload
                  onChange={(e) => {
                    imgUpload(e, "THUMBNAIL");
                  }}
                  id={"profileImg"}
                >
                  <div className="w-full h-[418px] MBody16 flex flex-col justify-center text-center text-Blue04 border border-[#D9D9D9] border-dashed">
                    <div>프로필 이미지 업로드</div>
                    <IconPlus className="mx-auto mt-4" />
                  </div>
                </ImgUpload>
              )}
            </TitleWrap>
            <TitleWrap
              title={"추가사진 업로드"}
              subTitle={`최대 15장 (0/15)`}
              require
            >
              <ImgUpload
                multiple
                onChange={(e) => {
                  imgUpload(e, "ADDITIONAL_IMAGE");
                }}
                id={"ADDITIONAL_IMAGE"}
              >
                <div className="border border-[#D9D9D9] border-dashed text-center text-Blue04 py-4 MBody16">
                  <div>추가 사진 업로드</div>
                  <IconPlus className="mx-auto mt-4" />
                </div>
              </ImgUpload>
              <div className="flex flex-wrap gap-3 mt-4 items-center">
                {artistProfile?.attachments
                  .filter((item) => item.type === "ADDITIONAL_IMAGE")
                  .map((item, idx) => (
                    <div key={item.id} className="relative">
                      <img
                        className="w-[110.5px] h-[112px] rounded-[10px]"
                        src={`https://test.castingvote.tv/uploads${item.url}`}
                        alt="ADDITIONAL_IMAGE"
                      />
                      <XButton
                        onClick={() => {
                          handleDeleteImage(item.id);
                        }}
                        className="absolute cursor-pointer top-4 right-4"
                      />
                    </div>
                  ))}
              </div>
            </TitleWrap>
            <TitleWrap
              className="!gap-2"
              title={"최근 3개월 이내 사진 업로드"}
              subTitle={`스튜디오 촬영x`}
              require
            >
              <div>
                <div className="flex items-start flex-wrap gap-2">
                  {artistProfile?.attachments.some(
                    (item) => item.type === "FULL_BODY"
                  ) ? (
                    artistProfile?.attachments
                      .filter((item) => item.type === "FULL_BODY")
                      .map((item, idx) => (
                        <div key={item.id} className="relative">
                          <img
                            className="w-[176px] h-[176px]"
                            src={`https://test.castingvote.tv/uploads${item.url}`}
                            alt="FULL_BODY"
                          />
                          <XButton
                            onClick={() => {
                              handleDeleteImage(item.id);
                            }}
                            className="absolute cursor-pointer top-4 right-4"
                          />
                        </div>
                      ))
                  ) : (
                    <ImgUpload
                      onChange={(e) => {
                        imgUpload(e, "FULL_BODY");
                      }}
                      id={"FULL_BODY"}
                    >
                      <div className="text-Blue04 cursor-pointer MBody16 w-[176px] h-[176px] border border-[#D9D9D9] border-dashed flex flex-col justify-center mx-auto text-center">
                        <div>전신사진</div>
                        <IconPlus className="mx-auto mt-4" />
                      </div>
                    </ImgUpload>
                  )}
                  {artistProfile?.attachments.some(
                    (item) => item.type === "FRONT_HALF_BODY"
                  ) ? (
                    artistProfile?.attachments
                      .filter((item) => item.type === "FRONT_HALF_BODY")
                      .map((item, idx) => (
                        <div key={item.id} className="relative">
                          <img
                            key={idx}
                            className="w-[176px] h-[176px]"
                            src={`https://test.castingvote.tv/uploads${item.url}`}
                            alt="FRONT_HALF_BODY"
                          />
                          <XButton
                            onClick={() => {
                              handleDeleteImage(item.id);
                            }}
                            className="absolute cursor-pointer top-4 right-4"
                          />
                        </div>
                      ))
                  ) : (
                    <ImgUpload
                      onChange={(e) => {
                        imgUpload(e, "FRONT_HALF_BODY");
                      }}
                      id={"FRONT_HALF_BODY"}
                    >
                      <div className="text-Blue04 MBody16 w-[176px] h-[176px] border border-[#D9D9D9] border-dashed flex flex-col justify-center mx-auto text-center">
                        <div>정면 상반신 사진</div>
                        <IconPlus className="mx-auto mt-4" />
                      </div>
                    </ImgUpload>
                  )}
                  {artistProfile?.attachments.some(
                    (item) => item.type === "LEFT_HALF_BODY"
                  ) ? (
                    artistProfile?.attachments
                      .filter((item) => item.type === "LEFT_HALF_BODY")
                      .map((item, idx) => (
                        <div key={item.id} className="relative">
                          <img
                            key={idx}
                            className="w-[176px] h-[176px]"
                            src={`https://test.castingvote.tv/uploads${item.url}`}
                            alt="LEFT_HALF_BODY"
                          />
                          <XButton
                            onClick={() => {
                              handleDeleteImage(item.id);
                            }}
                            className="absolute cursor-pointer top-4 right-4"
                          />
                        </div>
                      ))
                  ) : (
                    <ImgUpload
                      onChange={(e) => {
                        imgUpload(e, "LEFT_HALF_BODY");
                      }}
                      id={"LEFT_HALF_BODY"}
                    >
                      <div className="text-Blue04 MBody16 border border-[#D9D9D9] border-dashed w-[176px] h-[176px] flex flex-col justify-center mx-auto text-center">
                        <div>
                          좌측 상반신 사진
                          <br />
                          (45도 각도)
                        </div>
                        <IconPlus className="mx-auto mt-4" />
                      </div>
                    </ImgUpload>
                  )}
                  {artistProfile?.attachments.some(
                    (item) => item.type === "RIGHT_HALF_BODY"
                  ) ? (
                    artistProfile?.attachments
                      .filter((item) => item.type === "RIGHT_HALF_BODY")
                      .map((item, idx) => (
                        <div key={item.id} className="relative">
                          <img
                            className="w-[176px] h-[176px]"
                            src={`https://test.castingvote.tv/uploads${item.url}`}
                            alt="RIGHT_HALF_BODY"
                          />
                          <XButton
                            onClick={() => {
                              handleDeleteImage(item.id);
                            }}
                            className="absolute cursor-pointer top-4 right-4"
                          />
                        </div>
                      ))
                  ) : (
                    <ImgUpload
                      onChange={(e) => {
                        imgUpload(e, "RIGHT_HALF_BODY");
                      }}
                      id={"RIGHT_HALF_BODY"}
                    >
                      <div className="text-Blue04 MBody16 border border-[#D9D9D9] border-dashed w-[176px] h-[176px] flex flex-col justify-center mx-auto text-center">
                        <div>
                          우측 상반신 사진
                          <br />
                          (45도 각도)
                        </div>
                        <IconPlus className="mx-auto mt-4" />
                      </div>
                    </ImgUpload>
                  )}
                </div>
              </div>
            </TitleWrap>
            <TitleWrap
              title={"영상 업로드"}
              subTitle={`유튜브 링크를 첨부해 주세요`}
              require
            >
              <div className="flex items-center gap-2 w-full">
                <Input
                  value={youtubeLink}
                  onChange={(e) => {
                    setYoutubeLink(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (youtubeLink !== "") {
                      if (e.key === "Enter") {
                        addVideoLink(youtubeLink, "YOUTUBE");
                        setYoutubeLink("");
                      }
                    }
                  }}
                  className="w-[480px]"
                  placeholder="유튜브 링크를 추가해 주세요"
                  type={"text"}
                ></Input>
                <Button
                  onClick={() => {
                    if (youtubeLink !== "") {
                      addVideoLink(youtubeLink, "YOUTUBE");
                      setYoutubeLink("");
                    }
                  }}
                  className="w-[102px] text-Blue04 bg-Blue01 !MBody16 border-none"
                  text="추가"
                ></Button>
              </div>
              <div className="MBody14 w-full text-Gray09 my-4">
                💡드래그앤드롭으로 영상 순서를 바꿀 수 있어요
              </div>
              <div className="flex flex-col w-full gap-2">
                {artistProfile.videoLinks.map((item, idx) => {
                  let link = "";

                  if (item.link.startsWith("https://www.youtube.com")) {
                    link = item.link.split("watch?v=")[1];
                  } else {
                    link = item.link.split("?")[0].split("/")[3];
                  }
                  return (
                    <div key={item.orderNumber} className="relative group">
                      <YouTube
                        className={"w-full"}
                        key={item.orderNumber}
                        opts={opt}
                        iframeClassName="youtubeRounded"
                        videoId={link}
                      />
                      <XButton
                        onClick={() => {
                          handleDeleteVideo(item.link);
                        }}
                        className="absolute hidden cursor-pointer top-4 right-4 w-[38px] h-[38px] bg-black/80 group-hover:block"
                      />
                    </div>
                  );
                })}
              </div>
              {/* <ImgUpload
              onChange={(e) => {
                imgUpload(e, "ADDITIONAL_IMAGE");
              }}
              id={"ADDITIONAL_IMAGE"}
            >
              <div className="border border-[#D9D9D9] border-dashed text-center text-Blue04 py-4 MBody16">
                <div>영상 업로드</div>
                <IconPlus className="mx-auto mt-4" />
              </div>
            </ImgUpload> */}
            </TitleWrap>
          </div>
          <div className="flex-1 flex flex-col gap-10 ">
            <TitleWrap title={"직업"} require>
              <div className="flex items-center flex-wrap gap-2">
                {jopList.map((item) => {
                  return (
                    <Chip
                      selected={artistProfile.job === item.name}
                      onClick={() => {
                        if (artistProfile.job === item.name) {
                          setArtistProfile({ ...artistProfile, job: "" });
                        } else {
                          setArtistProfile({
                            ...artistProfile,
                            job: item.name,
                          });
                        }
                      }}
                      key={item.id}
                      title={item.title}
                    ></Chip>
                  );
                })}
              </div>
            </TitleWrap>
            <TitleWrap title={"활동명"} require>
              <Input
                value={artistProfile?.name}
                onChange={(e) => {
                  setArtistProfile({ ...artistProfile, name: e.target.value });
                }}
                className="w-[589px]"
                placeholder="활동명을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title={"SNS"}>
              <Input
                value={artistProfile?.instagram}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    instagram: e.target.value,
                  });
                }}
                imagePosition="L"
                className="w-[589px] h-12 mb-4"
                placeholder="인스타그램 ID"
                type={"text"}
              >
                <ProfileInsta className="left-2.5 top-2.5 absolute" />
              </Input>
              <Input
                value={artistProfile?.youtube}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    youtube: e.target.value,
                  });
                }}
                imagePosition="L"
                className="w-[589px] h-12"
                placeholder="유튜브 채널 URL (영상링크 x /채널 링크를 올려 주세요)"
                type={"text"}
              >
                <ProfileYoutube className="left-2.5 top-2.5 absolute" />
              </Input>
            </TitleWrap>
            <TitleWrap title={"키워드"}>
              <div className="flex items-center flex-wrap gap-2">
                {keyword.map((item) => {
                  return (
                    <Chip
                      selected={artistProfile?.hashtags.includes(item)}
                      onClick={() => {
                        if (artistProfile?.hashtags.includes(item)) {
                          setArtistProfile({
                            ...artistProfile,
                            hashtags: [
                              ...artistProfile?.hashtags.filter(
                                (hashTag) => hashTag !== item
                              ),
                            ],
                          });
                        } else {
                          setArtistProfile({
                            ...artistProfile,
                            hashtags: [...artistProfile?.hashtags, item],
                          });
                        }
                      }}
                      key={item}
                      title={item}
                    ></Chip>
                  );
                })}
              </div>
            </TitleWrap>
            <TitleWrap title={"직접입력"} subTitle="최대 10자">
              <div className="flex items-center gap-2">
                <Input
                  value={keywordItem}
                  onChange={(e) => {
                    setKeywordItem(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (keywordItem !== "") {
                      if (e.key === "Enter") {
                        setArtistProfile({
                          ...artistProfile,
                          hashtags: [
                            ...artistProfile.hashtags,
                            keywordItem.trim(),
                          ],
                        });
                        setKeywordItem("");
                      }
                    }
                  }}
                  className="w-[480px]"
                  placeholder="키워드를 추가해 주세요"
                  type={"text"}
                ></Input>
                <Button
                  onClick={() => {
                    if (keywordItem !== "") {
                      setArtistProfile({
                        ...artistProfile,
                        hashtags: [...artistProfile.hashtags, keywordItem],
                      });
                      setKeywordItem("");
                    }
                  }}
                  className="w-[102px] text-Blue04 bg-Blue01 !MBody16 border-none"
                  text="추가"
                ></Button>
              </div>
              <div className="flex items-center flex-wrap mt-4 gap-2">
                {artistProfile.hashtags
                  .filter((hashtags) => !keyword.includes(hashtags))
                  .map((hashtag, index) => (
                    <Chip
                      closeButton
                      selected
                      key={index}
                      onClick={() => {
                        setArtistProfile({
                          ...artistProfile,
                          hashtags: artistProfile.hashtags.filter(
                            (x) => x !== hashtag
                          ),
                        });
                      }}
                      title={hashtag}
                    ></Chip>
                  ))}
              </div>
            </TitleWrap>
            <hr className="h-px w-full text-Gray03" />
            <div className="flex items-center gap-5">
              <TitleWrap title={"키"} require>
                <Input
                  value={String(artistProfile?.height)}
                  onChange={(e) => {
                    setArtistProfile({
                      ...artistProfile,
                      height: Number(e.target.value.replace(/[^0-9]/g, "")),
                    });
                  }}
                  className="w-[285px]"
                  placeholder="키를 입력해 주세요 (cm)"
                  type={"text"}
                ></Input>
              </TitleWrap>
              <TitleWrap title="몸무게" require>
                <Input
                  value={String(artistProfile?.weight)}
                  onChange={(e) => {
                    setArtistProfile({
                      ...artistProfile,
                      weight: Number(e.target.value.replace(/[^0-9]/g, "")),
                    });
                  }}
                  className="w-[285px]"
                  placeholder="몸무게를 입력해 주세요 (kg)"
                  type={"text"}
                ></Input>
              </TitleWrap>
            </div>
            <div className="flex items-center gap-5">
              <TitleWrap title={"신발사이즈"}>
                <Input
                  value={String(artistProfile?.shoeSize)}
                  onChange={(e) => {
                    setArtistProfile({
                      ...artistProfile,
                      shoeSize: Number(e.target.value.replace(/[^0-9]/g, "")),
                    });
                  }}
                  className="w-[285px]"
                  placeholder="신발사이즈를 입력해 주세요 (cm)"
                  type={"text"}
                ></Input>
              </TitleWrap>
              <TitleWrap title="3size">
                <Input
                  value={size}
                  onChange={(e) => {
                    const value = e.target.value;
                    let numberValue = value.replace(/[^0-9]/g, "");

                    if (numberValue.length > 2) {
                      numberValue =
                        numberValue.slice(0, 2) + "-" + numberValue.slice(2);
                    }
                    if (numberValue.length > 5) {
                      numberValue =
                        numberValue.slice(0, 5) + "-" + numberValue.slice(5, 7);
                    }

                    setSize(numberValue);
                  }}
                  className="w-[285px]"
                  placeholder="3size를 입력해 주세요"
                  type={"text"}
                ></Input>
              </TitleWrap>
            </div>
            <div>
              <TitleWrap title="학력">
                <Input
                  value={artistProfile?.education}
                  onChange={(e) => {
                    setArtistProfile({
                      ...artistProfile,
                      education: e.target.value,
                    });
                  }}
                  className="w-[589px] mb-4"
                  placeholder="최종학력을 입력해 주세요"
                  type={"text"}
                ></Input>
                <div className="flex items-center">
                  <Checkbox id="학력" title={"비공개"}></Checkbox>
                  <div className="group relative">
                    <Tooltip />
                    <div className="absolute group-hover:block hidden RBody14 rounded-[10px] p-2 border box-content border-Gray03 text-Gray09 top-6 bg-white left-0 w-[308px]">
                      아티스트 유저에게는 가려지고 스탭 유저에게만 공개돼요
                    </div>
                  </div>
                </div>
              </TitleWrap>
              <div>
                <div className="flex items-center gap-5 my-4">
                  <TitleWrap title={"출연료"}>
                    <Input
                      value={String(artistProfile?.salary)}
                      onChange={(e) => {
                        setArtistProfile({
                          ...artistProfile,
                          salary: Number(e.target.value.replace(/[^0-9]/g, "")),
                        });
                      }}
                      className="w-[285px]"
                      placeholder="희망하는 출연료를 입력해 주세요"
                      type={"text"}
                    ></Input>
                  </TitleWrap>
                  <TitleWrap title="매니저/소속사">
                    <Input
                      value={artistProfile?.agency}
                      onChange={(e) => {
                        setArtistProfile({
                          ...artistProfile,
                          agency: e.target.value,
                        });
                      }}
                      className="w-[285px]"
                      placeholder="매니저/소속사를 입력해 주세요"
                      type={"text"}
                    ></Input>
                  </TitleWrap>
                </div>
                <div className="flex items-center">
                  <Checkbox id="출연료" title={"비공개"}></Checkbox>
                  <div className="group relative">
                    <Tooltip />
                    <div className="absolute group-hover:block hidden RBody14 rounded-[10px] p-2 border box-content border-Gray03 text-Gray09 top-6 bg-white left-0 w-[308px]">
                      아티스트 유저에게는 가려지고 스탭 유저에게만 공개돼요
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TitleWrap title="특기">
              <div>
                <div className="flex items-center gap-2">
                  <Input
                    value={hobbyItem}
                    onChange={(e) => {
                      setHobbyItem(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (hobbyItem !== "") {
                        if (e.key === "Enter") {
                          setArtistProfile({
                            ...artistProfile,
                            hobbies: [
                              ...artistProfile.hobbies,
                              hobbyItem.trim(),
                            ],
                          });
                          setHobbyItem("");
                        }
                      }
                    }}
                    className="w-[480px]"
                    placeholder="특기를 추가해 주세요"
                    type={"text"}
                  ></Input>
                  <Button
                    onClick={() => {
                      if (hobbyItem !== "") {
                        setArtistProfile({
                          ...artistProfile,
                          hobbies: [...artistProfile.hobbies, hobbyItem],
                        });
                        setHobbyItem("");
                      }
                    }}
                    className="w-[102px] text-Blue04 bg-Blue01 !MBody16 border-none"
                    text="추가"
                  ></Button>
                </div>
                <div className="flex items-center flex-wrap mt-4 gap-2">
                  {artistProfile.hobbies.map((item, idx) => {
                    return (
                      <Chip
                        closeButton
                        key={idx}
                        selected
                        onClick={() => {
                          setArtistProfile({
                            ...artistProfile,
                            hobbies: artistProfile.hobbies.filter(
                              (x) => x !== item
                            ),
                          });
                        }}
                        title={item}
                      ></Chip>
                    );
                  })}
                </div>
              </div>
            </TitleWrap>
            <TitleWrap title="거주지">
              <Input
                value={artistProfile?.sido}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    sido: e.target.value,
                  });
                }}
                className="w-[589px]"
                placeholder="거주지를 입력해 주세요(시까지 입력)"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="자기소개">
              <Textarea
                value={artistProfile?.introduce}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    introduce: e.target.value,
                  });
                }}
                placeholder="자기소개를 입력해 주세요 (최대 2000자)"
                className="w-full h-[270px]"
              />
            </TitleWrap>
            <hr className="h-px w-full text-Gray03" />
            <div>
              <div className="flex mb-4 justify-between items-center">
                <div className="BBody20 text-Gray09">필모그래피</div>
                <IconPlus
                  onClick={() => {
                    addFilmographyItem();
                  }}
                  className="cursor-pointer"
                />
              </div>
              {artistProfile.filmographies.map((item, idx) => {
                return (
                  <div key={idx} className="flex flex-col gap-4">
                    {idx !== 0 && (
                      <div
                        onClick={() => {
                          removeFilmographyItem(idx);
                        }}
                        className="mt-4 flex items-center gap-2 cursor-pointer"
                      >
                        <hr className="border-t border-dashed bg-Gray03 flex-1" />
                        <Delete />
                        <hr className="border-t border-dashed bg-Gray03 flex-1" />
                      </div>
                    )}

                    <div className={`flex items-center gap-5`}>
                      <TitleWrap
                        title={"제작연도"}
                        subTitle="0000로 적어주세요"
                      >
                        <Input
                          value={String(
                            artistProfile?.filmographies[idx].releaseYear
                          )}
                          onChange={(e) => {
                            const updatedFilmographies = [
                              ...artistProfile.filmographies,
                            ];
                            updatedFilmographies[idx].releaseYear = Number(
                              e.target.value.replace(/[^0-9]/g, "")
                            );
                            setArtistProfile({
                              ...artistProfile,
                              filmographies: updatedFilmographies,
                            });
                          }}
                          placeholder=""
                          type={"text"}
                        ></Input>
                      </TitleWrap>
                      <TitleWrap title="분류">
                        <Dropdown
                          innerText={
                            artistProfile.filmographies[idx].type === "MOVIE"
                              ? "영화"
                              : artistProfile.filmographies[idx].type ===
                                "TV_SERIES"
                              ? "드라마"
                              : artistProfile.filmographies[idx].type ===
                                "MOVIE"
                              ? "영화"
                              : artistProfile.filmographies[idx].type ===
                                "MUSIC_VIDEO"
                              ? "뮤직비디오"
                              : artistProfile.filmographies[idx].type ===
                                "WEB_SERIES"
                              ? "웹드라마"
                              : artistProfile.filmographies[idx].type ===
                                "SHORT_FILM"
                              ? "숏폼"
                              : "선택"
                          }
                          width="w-[170px]"
                        >
                          <div>
                            {typeList.map((item) => {
                              return (
                                <li
                                  onClick={() => {
                                    const updatedFilmographies = [
                                      ...artistProfile.filmographies,
                                    ];
                                    updatedFilmographies[idx].type = item.name;
                                    setArtistProfile({
                                      ...artistProfile,
                                      filmographies: updatedFilmographies,
                                    });
                                  }}
                                  key={item.id}
                                >
                                  {item.title}
                                </li>
                              );
                            })}
                          </div>
                        </Dropdown>
                      </TitleWrap>
                    </div>
                    <TitleWrap title="작품 제목" subTitle="최대 36자">
                      <Input
                        value={artistProfile?.filmographies[idx].title}
                        onChange={(e) => {
                          const updatedFilmographies = [
                            ...artistProfile.filmographies,
                          ];
                          updatedFilmographies[idx].title = e.target.value;
                          setArtistProfile({
                            ...artistProfile,
                            filmographies: updatedFilmographies,
                          });
                        }}
                        className="w-[589px]"
                        placeholder="작품 제목을 입력해 주세요"
                        type={"text"}
                      ></Input>
                    </TitleWrap>
                    <div className="flex items-center gap-5">
                      <TitleWrap title="배역">
                        <Dropdown
                          innerText={
                            artistProfile.filmographies[idx].role ===
                            "LEADING_ACTOR"
                              ? "주연"
                              : artistProfile.filmographies[idx].role ===
                                "SUPPORTING_ACTOR"
                              ? "조연"
                              : artistProfile.filmographies[idx].role ===
                                "BIT_PART"
                              ? "단역"
                              : artistProfile.filmographies[idx].role ===
                                "CAMEO"
                              ? "카메오"
                              : "선택"
                          }
                          width="w-[170px]"
                        >
                          <div>
                            {roleList.map((item) => {
                              return (
                                <li
                                  onClick={() => {
                                    const updatedFilmographies = [
                                      ...artistProfile.filmographies,
                                    ];
                                    updatedFilmographies[idx].role = item.name;
                                    setArtistProfile({
                                      ...artistProfile,
                                      filmographies: updatedFilmographies,
                                    });
                                  }}
                                  key={item.id}
                                >
                                  {item.title}
                                </li>
                              );
                            })}
                          </div>
                        </Dropdown>
                      </TitleWrap>
                      <TitleWrap title={"배역명"}>
                        <Input
                          value={artistProfile?.filmographies[idx].description}
                          onChange={(e) => {
                            const updatedFilmographies = [
                              ...artistProfile.filmographies,
                            ];
                            updatedFilmographies[idx].description =
                              e.target.value;
                            setArtistProfile({
                              ...artistProfile,
                              filmographies: updatedFilmographies,
                            });
                          }}
                          className="w-[285px]"
                          placeholder="배역명을 입력해 주세요"
                          type={"text"}
                        ></Input>
                      </TitleWrap>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </ContentWrap>
      <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 border-t border-Gray03 mt-5 py-4 px-5">
        <Button
          onClick={() => {
            if (ArtistData === null) {
              mutate(artistProfile, {
                onSuccess: () => {
                  navigate("/artist");
                },
              });
            } else {
              updateMutate(updataProfile, {
                onSuccess: () => {
                  navigate("/artist/my");
                },
              });
            }
          }}
          className="w-full h-12 bg-Blue04 text-white border-none"
          text={`${ArtistData === null ? "등록" : "수정"}하기`}
        ></Button>
      </div>
    </div>
  );
};

export default CreateArtistProfile;
