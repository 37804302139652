import { atom } from "recoil";

export const info = atom<{id:string, username: string, role: string}>({
    key: 'info',
    default: {
        id:"",
        username:"",
        role:"",
    } ,
  });


  export const loginCheck = atom<boolean>({
    key:'loginCheck',
    default: false
  })