import React, { useState } from "react";

import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import TitleWrap from "components/TitleWrap";
import { ReactComponent as IconPlus } from "assets/icon_plus.svg";
import Input from "components/Input";
import Textarea from "components/Textarea";
import Button from "components/Button";
import Dropdown from "components/Dropdown";

const CreateCastingCall = () => {
  const [castingCallData, setCastingCallData] = useState({
    productionId: 17,
    type: "AUDITION",
    title: "title",
    description: "description",
    deadlineDate: "2024-11-12",  // Format: yyyy-MM-dd
    deadlineType: "DEADLINE",
    location: "location",
    startDate: "2024-12-12",      // Format: yyyy-MM-dd
    endDate: "2024-12-22",        // Format: yyyy-MM-dd
    role: "LEADING_ACTOR",
    episodeInfo: "episodeInfo",
    minAge: 10,
    maxAge: 20,
    ageType: "RESTRICTED",
    gender: "ALL",
    numberOfPositions: 1,
    fee: 1000,
    attachments: [
      {
        id: 9,
        type: "THUMBNAIL",
        orderNumber: 1
      }
    ],
    stakeholders: [
      {
        id: 51,
        role: "DECISION_MAKER"
      },
      {
        id: 52,
        role: "PARTICIPANT"
      }
    ]
  });
  const type = [
    { id: 0, name: "주연" },
    { id: 1, name: "조연" },
    { id: 2, name: "단역" },
    { id: 3, name: "이미지단역" },
    { id: 4, name: "보조출연" },
    { id: 5, name: "대역" },
  ];
  const division = [
    { id: 0, name: "오디션", tytie:"AUDITION" },
    { id: 1, name: "출연", tytie:"CAST" },
  ];

  const gender = [
    { id: 0, name: "남자" },
    { id: 1, name: "여자 " },
    { id: 2, name: "성별무관" },
  ];


  return (
    <ContentWrap className="pb-20">
      <HeaderWithBackButton title={"공고등록"} />
      <div className="pt-20 flex flex-col gap-10">
        <div className="flex items-center gap-4">
          <TitleWrap title={"작품"} require>
            <Dropdown innerText={"선택"} width="w-[235px]"></Dropdown>
          </TitleWrap>
          <TitleWrap title={"구분"}>
            <Dropdown innerText={"구분"} width="w-[109px]">
              <div>
                {division.map((item) => {
                  return (
                    <li
                      //   onClick={() => {
                      //     const updatedFilmographies = [
                      //       ...artistProfile.filmographies,
                      //     ];
                      //     updatedFilmographies[idx].type = item.name;
                      //     setArtistProfile({
                      //       ...artistProfile,
                      //       filmographies: updatedFilmographies,
                      //     });
                      //   }}
                      key={item.id}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>
        </div>
        <TitleWrap require title={"공고 제목"}>
          <Input type={"text"} placeholder="공고 제목을 입력해주세요" />
        </TitleWrap>

        <TitleWrap title={"대표 썸네일 사진 업로드"}>
          <div className="w-full h-[360px] MBody16 flex flex-col justify-center text-center text-Blue04 border border-[#D9D9D9] border-dashed">
            <div>썸네일 사진 업로드</div>
            <IconPlus className="mx-auto mt-4" />
          </div>
        </TitleWrap>

        <div className="flex items-center gap-4">
          <TitleWrap title={"배역"} require>
            <Dropdown width="w-[172px]" innerText={"선택"}>
              <div>
                {type.map((item) => {
                  return (
                    <li
                      //   onClick={() => {
                      //     const updatedFilmographies = [
                      //       ...artistProfile.filmographies,
                      //     ];
                      //     updatedFilmographies[idx].type = item.name;
                      //     setArtistProfile({
                      //       ...artistProfile,
                      //       filmographies: updatedFilmographies,
                      //     });
                      //   }}
                      key={item.id}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>

          <TitleWrap title={"모집인원"}>
            <Input type={"text"} placeholder="입력해주세요" />
          </TitleWrap>
        </div>
        <div className="flex items-center gap-4">
          <TitleWrap title={"성별"} require>
            <Dropdown width="w-[172px]" innerText={"선택"}>
            <div>
                {gender.map((item) => {
                  return (
                    <li
                      //   onClick={() => {
                      //     const updatedFilmographies = [
                      //       ...artistProfile.filmographies,
                      //     ];
                      //     updatedFilmographies[idx].type = item.name;
                      //     setArtistProfile({
                      //       ...artistProfile,
                      //       filmographies: updatedFilmographies,
                      //     });
                      //   }}
                      key={item.id}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>

          <TitleWrap require title={"나이"}>
            <div className="flex items-center">
              <Input type={"text"} />
              ~
              <Input type={"text"} />
            </div>
          </TitleWrap>
        </div>
        <div className="flex items-center gap-4">
          <TitleWrap title={"촬영지역"}>
            <Dropdown width="w-[172px]" innerText={"선택"}></Dropdown>
          </TitleWrap>

          <TitleWrap title={"촬영날짜"}>
            <div className="flex items-center">
              <Input type={"text"} />
              ~
              <Input type={"text"} />
            </div>
          </TitleWrap>
        </div>
        <div className="flex items-center gap-4">
          <TitleWrap title={"출연료"}>
            <Input type={"text"} placeholder="입력해주세요" />
          </TitleWrap>

          <TitleWrap title={"출연회차"}>
            <Input type={"text"} placeholder="입력해주세요" />
          </TitleWrap>
        </div>
        <hr />
        <TitleWrap require title={"상세내용"}>
          <Textarea
            className="w-full h-[210px]"
            placeholder="상세내용을 입력해 주세요"
          />
        </TitleWrap>
        <div className="flex items-center gap-4">
          <TitleWrap
            subTitle="PDF파일만 올려주세요(최대 1개)"
            title={"첨부파일"}
          >
            <div className="flex items-center gap-4">
              <Button
                className="w-[109px] text-Blue04 bg-Blue01 border-none"
                text={"파일선택"}
              />
              <Input type={"text"} placeholder="입력해주세요" />
            </div>
          </TitleWrap>
        </div>
        <TitleWrap title={"자격요건"}>
          <Textarea
            className="w-full h-[105px]"
            placeholder="자격요건을 입력해 주세요"
          />
        </TitleWrap>
        <TitleWrap title={"우대사항"}>
          <Textarea
            className="w-full h-[105px]"
            placeholder="우대사항을 입력해 주세요"
          />
        </TitleWrap>
        <TitleWrap title={"마감일자"}>
          <Input type={"text"} placeholder="선택" />
        </TitleWrap>
        <div className="flex items-center gap-4">
          <TitleWrap title={"후보 확정 권한 추가"}>
            <Dropdown width="w-[172px]" innerText={"선택"}></Dropdown>
          </TitleWrap>

          <TitleWrap title={"참조 관계자 추가"}>
            <Dropdown width="w-[172px]" innerText={"선택"}></Dropdown>
          </TitleWrap>
        </div>
      </div>
      <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 border-t border-Gray03 mt-5 py-4 px-5">
        <Button
          className="w-full h-12 bg-Blue04 text-white border-none"
          text={`등록하기`}
        ></Button>
      </div>
    </ContentWrap>
  );
};

export default CreateCastingCall;
