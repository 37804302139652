import React from 'react';
import Footer from './Footer';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import TabBar from './TabBar';

const Layout = () => {
    return (
        <>
            <Header/>
            <div className='w-full min-w-[320px] max-w-[400px] pb-10 px-5 mx-auto'>
            <Outlet/>
            </div>
            <TabBar/>
            <Footer/>
        </>
    );
};

export default Layout;