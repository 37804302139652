import React from "react";
import ContentWrap from "components/ContentWrap";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import { ReactComponent as Share } from "../assets/share.svg";
import { ReactComponent as Edit } from "../assets/editButton.svg";
import NoProfile from "../assets/noProfile.png";

import Male from "assets/male.png";
import Female from "assets/female.png";

import Button from "components/Button";
import Chip from "components/Chip";
import StaffWorkCard from "components/StaffWorkCard";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { info } from "store/auth/atom";
import { useMyPage, useStaffDetail } from "api/staff";
import HeaderWithBackButton from "components/HeaderWithBackButton";

const StaffMy = () => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(info);

  const { staffId } = useParams();

  const { data } = useMyPage();
  const StaffData = data?.data.data;

  const sortedFilmo = StaffData?.filmographies.sort(
    (a, b) => Number(b.releaseYear) - Number(a.releaseYear)
  );
  console.log(StaffData);

  return (
    <div>
      <HeaderWithBackButton title="내 프로필" />
      {StaffData === null ? (
        <ContentWrap className="text-center mx-auto flex-col py-20">
          <img
            className="w-[60px] h-[60px] mx-auto"
            src={NoProfile}
            alt="NoProfile"
          />
          <div className="mt-4 mb-10 MBody16 text-Gray05 whitespace-normal">
            아직 프로필이 없어요.
            <br />
            프로필을 등록하면 캐스팅을 시작할 수 있어요!
          </div>
          <Button
            className="px-4 py-3 text-Gray00 bg-Blue04 border-none"
            text={"프로필 등록하기"}
            onClick={() => {
              navigate("/staff/profile");
            }}
          />
        </ContentWrap>
      ) : (
        <ContentWrap className="flex py-20 flex-col">
          <div>
            <div className="w-full mt-4 items-start flex gap-7">
              <img
                src={
                  !StaffData ||
                  (StaffData?.thumbnailUrl === null &&
                    StaffData?.gender.code === "MALE")
                    ? Male
                    : !StaffData ||
                      (StaffData?.thumbnailUrl === null &&
                        StaffData?.gender.code === "FEMALE")
                    ? Female
                    : `https://test.castingvote.tv/uploads${StaffData?.thumbnailUrl}`
                }
                alt="Thumnai"
                className="w-[160px] h-[196px] rounded-[10px]"
              />
              <div>
                <div className="flex items-center gap-2 BBody20 text-Gray09">
                  <div>
                    {StaffData?.profileName ? StaffData?.profileName : "홍길동"}
                  </div>
                  <div className="flex items-center">
                    <Bookmark />
                    <Share />
                  </div>
                  <div
                    onClick={() => {
                      navigate("/staff/profile");
                    }}
                    className="flex items-center gpa-2 cursor-pointer"
                  >
                    <Edit />
                    <div className="text-Blue04 BBody16">수정</div>
                  </div>
                </div>
                <div className="my-2 MCaption12 flex items-center gap-2 text-[#898A8D]">
                  {/* MANAGER, CASTING_DIRECTOR, DIRECTOR, PRODUCER */}
                  <div>
                    {userInfo?.role === "MANAGER"
                      ? "매니저"
                      : userInfo?.role === "CASTING_DIRECTOR"
                      ? "캐스팅 디렉터"
                      : userInfo?.role === "DIRECTOR"
                      ? "제작자"
                      : "프로듀서"}
                  </div>
                  <div>•</div>
                  <div className="overflow-hidden text-ellipsis w-[91px]">
                    {StaffData?.basicInfo.agency}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  {sortedFilmo?.map((item, i) => {
                    return (
                      <div key={i} className="flex items-start gap-2">
                        <div className="RBody14 text-[#898A8D]">
                          {item?.releaseYear}
                        </div>
                        <div className="MBody14 text-Gray09">{item?.title}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <Button
              className="w-full bg-Blue04 py-3 text-white !border-none mt-4"
              text={"프로필 보내기"}
            ></Button>
          </div>
          <div className="w-full">
            <div className="my-4">
              <Chip title={"전체"}></Chip>
            </div>
            <div className=" gap-x-4 gap-y-2 grid grid-cols-2">
              <StaffWorkCard onClick={() => navigate("/staff/filmo/3")} />
              <StaffWorkCard />
              <StaffWorkCard />
              <StaffWorkCard />
            </div>
          </div>
        </ContentWrap>
      )}
    </div>
  );
};

export default StaffMy;
