import React, { useEffect, useState } from "react";
import Logo from "../assets/CastingVote_logo.png";
import { ReactComponent as Noti } from "../assets/notification_icon.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Button from "./Button";
import ContentWrap from "./ContentWrap";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/login") return null;
  if (location.pathname === "/signup") return null;
  if (location.pathname === "/artist/my") return null;
  if (location.pathname === "/artist/profile") return null;
  if (location.pathname === "/my") return null;

  
  if (location.pathname === "/staff/my") return null;
  if (location.pathname === "/staff/profile") return null;
  if (location.pathname === "/staff/post") return null;
  if (location.pathname === "/staff/casting-call") return null;
  if (location.pathname === "/staff/casting/my") return null;
  if (location.pathname === "/staff/casting-call/my") return null;
  if (location.pathname === `/staff/casting-call/my?id=3`) return null;

  return (
    <ContentWrap className="flex justify-between mx-auto px-5 pt-4">
      <div className="flex w-full justify-between items-center">
        <img
          onClick={() => {
            navigate("/");
          }}
          className="w-[76px] h-7 mr-6 cursor-pointer"
          src={Logo}
          alt="로고"
        />
        <Noti />
      </div>
    </ContentWrap>
  );
};

export default Header;
